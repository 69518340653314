import { AnalyticUtils } from 'wikr-core-analytics';
import Core from 'testania';
import { ERROR_LEVELS, SENTRY_ANALYTIC, SENTRY_APP } from 'sentry-utils';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';

import config from 'config';

import api from 'apiSingleton';

import { selectEmail, selectPlatform } from 'redux/User/selectors';
import { SIGN_UP } from 'redux/User/actionTypes';
import { setToken, setUserEmail, setUserId, signUpSuccess, signUpFail, setSignUpErrorData } from 'redux/User/actions';
import { selectTestaniaName } from 'redux/Testania/selectors';
import {
    selectAge,
    selectCurrentWeight,
    selectGender,
    selectGoal,
    selectMeasureSystem,
    selectTall,
    selectTargetWeight,
    selectTargetZone,
    selectUrlParams,
    selectWantBody,
    selectName,
    selectCurrentBody,
    selectTrainingLevel,
    selectOccasion,
    selectOccasionDate,
    selectTrainingDays,
    selectEquipment,
    selectBodyParts,
    selectGoalId,
    selectUserWorkoutTypes,
    selectStepTracker,
    selectExtraWorkoutType,
} from 'redux/Onboadring/selectors';

import { DEFAULT_ERROR_MESSAGE } from 'constants/values';
import { LTV_COEFFICIENT_KEY } from 'constants/payments';

import sentry from 'services/Sentry/SentryInstance';

import { ageToDate, cleanObject, getLanguage } from 'helpers/utils';
import { getDeviceOS, getProjectEmail } from 'helpers/settings';
import { isEmptyValue, isObject } from 'helpers/root/utils';
import { getExtraWorkoutTypesByName } from 'helpers/onboardingFlow/utils';

import { SignUpActionData, UserInfoResponse, UserSignUpError } from 'types/user/userApiInterface';
import { ActionType, Awaited } from 'types/commonInterfaces';

export function* signUp({ payload }: ActionType<SignUpActionData>) {
    const age: number = yield select(selectAge);
    const email: string = yield select(selectEmail);
    const gender: string = yield select(selectGender);
    const height: string = yield select(selectTall);
    const weight: string = yield select(selectCurrentWeight);
    const targetWeight: string = yield select(selectTargetWeight);
    const targetZone: object = yield select(selectTargetZone);
    const urlParams: Record<string, string> = yield select(selectUrlParams);
    const measureSystem: string = yield select(selectMeasureSystem);
    const targetBodyType: string = yield select(selectWantBody);
    const currentBodyType: string = yield select(selectCurrentBody);
    const goal: string = yield select(selectGoal);
    const goalId: string = yield select(selectGoalId);
    const name: string = yield select(selectName);
    const trainingLevel: string = yield select(selectTrainingLevel);
    const trainingDays: string = yield select(selectTrainingDays);
    const equipment: string = yield select(selectEquipment);
    const bodyParts: string = yield select(selectBodyParts);
    const workoutTypesList: object[] = yield select(selectExtraWorkoutType);
    const userWorkoutTypes: string[] | null = yield select(selectUserWorkoutTypes);
    const testaniaName: string = yield select(selectTestaniaName);
    const occasionValue: string = yield select(selectOccasion);
    const occasionDate: string = yield select(selectOccasionDate);
    const userPlatform: string = yield select(selectPlatform);
    const userStepTracker: boolean | null = yield select(selectStepTracker);

    const localization = getLanguage();
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    const branch_name = urlParams?.['branch-name'] ?? null;
    const test_name = urlParams?.['test-name'] ?? null;

    const analyticData: Awaited<ReturnType<typeof AnalyticUtils.getAnalyticData>> = yield call(
        AnalyticUtils.getAnalyticData,
        'purchase'
    );
    // TODO: need fix typing in analytic lib
    const OSName = (analyticData?.browser_info as { 'os.name': string })['os.name'];
    const { version: OSVersion } = getDeviceOS();

    if (isEmptyValue(height)) {
        sentry.logError(new Error('Empty value Height on sign-up'), SENTRY_APP, ERROR_LEVELS.ERROR, {
            branch_name,
            test_name,
            ab_test_name: testaniaName,
        });
    }

    const extraWorkoutTypes =
        userWorkoutTypes &&
        getExtraWorkoutTypesByName({
            userWorkoutTypesList: userWorkoutTypes,
            workoutTypesList,
        });

    const planSettings = {
        training: {
            level: trainingLevel || 'beginner',
            ...(extraWorkoutTypes && { extra_workout_types: extraWorkoutTypes }),
            ...(trainingDays && { days: trainingDays }),
            ...(equipment && { equipment }),
            ...(bodyParts && { body_parts: bodyParts }),
        },
        ...(goalId && { goal_id: goalId }),
        ...(targetWeight && {
            weight_tracker: {
                target: parseFloat(targetWeight),
            },
        }),
        ...(userStepTracker !== null && {
            step_tracker: {
                is_on: userStepTracker,
            },
        }),
    };

    const meta = {
        email,
        gender,
        age: ageToDate(age),
        localization,
        target_zone: targetZone,
        branch_name,
        test_name,
        ab_test_name: testaniaName,
        units: measureSystem?.toLowerCase(),
        target_bodytype: targetBodyType,
        current_bodytype: currentBodyType,
        goal,
        timezone,
        platform: userPlatform,
        os_name: OSName,
        os_version: OSVersion?.toString(),
        plan_settings: planSettings,
        ...(height && { height: parseFloat(height) }),
        ...(weight && { weight: parseFloat(weight) }),
        ...(name && { name }),
        ...(occasionValue && { occasion: occasionValue.toLowerCase() }),
        ...(occasionDate && { occasion_date: occasionDate }),
    };

    try {
        const data: UserInfoResponse = yield api.user.signUp(cleanObject(meta));

        const { token, user_id, email, weight, height, gender } = data;

        sentry.setUser({ email, user_id });

        Core.calculateLTVCoefficient({
            age: Number(age),
            weight,
            height,
            gender,
            utm_source: urlParams?.['utm_source'] || 'other',
        })
            .then((coefficient) => {
                localStorage.setItem(LTV_COEFFICIENT_KEY, coefficient);
            })
            .catch((error) => {
                sentry.logError(error, SENTRY_ANALYTIC, ERROR_LEVELS.ERROR, {});
            });

        if (isEmptyValue(token)) {
            yield call(setErrorMessage, `Something went wrong. Please contact ${getProjectEmail()}`, payload);
        }

        if (!isEmptyValue(token)) {
            yield all([
                put(setToken(token)),
                put(setUserId(user_id)),
                put(setUserEmail(email)),
                put(signUpSuccess(data)),
            ]);

            payload.toNextPage();
        }
    } catch ({ error, errorData }) {
        const errorMessage = isObject(error) ? error?.error : error;

        yield put(signUpFail(errorMessage));
        yield call(handleErrorStatuses, errorData, payload);
    }
}

export function* setErrorMessage(message: string, payload: SignUpActionData) {
    yield put(signUpFail(message));

    yield call(payload.setError, message);
}

export function* handleErrorStatuses(errorData: AxiosResponse, payload: SignUpActionData) {
    const { status, data } = errorData;

    const errorInfo: UserSignUpError = {
        status,
        existInWeb: data?.exist_in_web,
        existInApp: data?.exist_in_app,
    };
    const errorMessage = data?.error || DEFAULT_ERROR_MESSAGE;

    if (status === 409) {
        errorInfo.redirectLink = data?.app_deeplink ?? config.USER_CABINET_URL;

        yield put(setSignUpErrorData(errorInfo));

        yield call(payload.resetForm);
    }

    yield call(setErrorMessage, errorMessage, payload);
}

export const signUpUser = [takeLatest(SIGN_UP, signUp)];
