import { UserStore } from 'types/store/userStore';
import { ActionType } from 'types/commonInterfaces';

import * as actionTypes from './actionTypes';

const initialState: UserStore = {
    email: '',
    user_id: null,
    userName: null,
    emailStatus: {
        isValid: false,
        message: '',
    },
    isLoading: false,
    token: null,
    payment_type: null,
    upsaleId: null,
    country: localStorage.getItem('country') || null,
    lastUpdateAt: null,
    isPaid: false,
    isInitTestania: true,
    shouldUserMoveToPage: false,
    defaultAmazonAnalyticData: null,
    isAutologinComplete: null,
    platform: null,
    signUpErrorData: {},
    isUpdateInfoSuccess: false,
    error: null,
};

const UserReducer = (state = initialState, action: ActionType<keyof UserStore>) => {
    switch (action.type) {
        case actionTypes.SIGN_UP:
            return {
                ...state,
                isLoading: true,
            };

        case actionTypes.SIGN_UP_SUCCESS:
            return {
                ...state,
                isLoading: false,
            };

        case actionTypes.UPDATE_INFO_SUCCESS:
            return {
                ...state,
                isUpdateInfoSuccess: true,
                isLoading: false,
            };

        case actionTypes.SIGN_UP_FAIL:
        case actionTypes.UPDATE_INFO_FAIL:
            return {
                ...state,
                isLoading: false,
                /* TODO fix type for error*/
                // @ts-ignore
                error: action.payload.error,
            };
        case actionTypes.SET_SIGN_UP_ERROR_DATA:
            return { ...state, signUpErrorData: action.payload };
        case actionTypes.SET_LOADER:
            return { ...state, isLoading: action.payload };
        case actionTypes.SET_EMAIL:
            return { ...state, email: action.payload };
        case actionTypes.SET_USER_ID:
            return { ...state, user_id: action.payload };
        case actionTypes.SET_USER_NAME:
            return { ...state, userName: action.payload };
        case actionTypes.SET_USER_TOKEN:
            localStorage.setItem('token', action.payload);

            return { ...state, token: action.payload };
        case actionTypes.SET_IS_INIT_TESTANIA:
            return { ...state, isInitTestania: action.payload };
        case actionTypes.SET_COUNTRY:
            localStorage.setItem('country', action.payload);

            return { ...state, country: action.payload };
        case actionTypes.SET_IS_PAID:
            return { ...state, isPaid: action.payload };
        case actionTypes.SET_UPDATED_AT_TIME:
            return { ...state, lastUpdateAt: action.payload };
        case actionTypes.SET_IS_AUTOLOGIN_COMPLETE:
            return { ...state, isAutologinComplete: action.payload };
        case actionTypes.SET_DEFAULT_AMAZON_ANALYTIC_DATA:
            return { ...state, defaultAmazonAnalyticData: action.payload };
        case actionTypes.SHOULD_USER_MOVE_TO_PAGE:
            return { ...state, shouldUserMoveToPage: action.payload };
        case actionTypes.SET_PLATFORM:
            return { ...state, platform: action.payload };
        default:
            return state;
    }
};

export default UserReducer;
