export const HIDE_HEADER = 'UI/HIDE_HEADER';

export const SHOW_MODAL = 'UI/SHOW_MODAL';
export const HIDE_MODAL = 'UI/HIDE_MODAL';

export const SET_LOADER_STATE = 'UI/SET_LOADER_STATE';
export const SET_INLINE_THEME = 'UI/SET_INLINE_THEME';
export const SET_LOGO_URL = 'UI/SET_LOGO_URL';

export const SHOW_SIDEBAR = 'UI/SHOW_SIDEBAR';

export const HIDE_PROGRESS_BAR = 'UI/HIDE_PROGRESS_BAR';
export const HIDE_PAYPAL = 'UI/HIDE_PAYPAL';

export const INIT_FRONT_CHAT = 'UI/INIT_FRONT_CHAT';
export const REDIRECT_TO = 'UI/REDIRECT_TO';
