import { Button as LibButton, IButton } from 'wikr-core-components';
import { useTranslation } from 'react-i18next';
import React, { FC } from 'react';

const Button: FC<IButton> = ({
    onClick,
    backgroundColor,
    textColor,
    hoverColor,
    size,
    text,
    disabled,
    isLoading,
    withPulsating,
    children,
    rightIcon,
    centerIcon,
    dataLocator,
    borderRadius,
    className,
    mb,
}) => {
    const { t } = useTranslation();

    return (
        <LibButton
            {...(text && { text: t(text) })}
            onClick={onClick}
            backgroundColor={backgroundColor}
            textColor={textColor}
            hoverColor={hoverColor}
            size={size}
            disabled={disabled}
            isLoading={isLoading}
            withPulsating={withPulsating}
            /* eslint-disable-next-line react/no-children-prop */
            children={children}
            rightIcon={rightIcon}
            centerIcon={centerIcon}
            dataLocator={dataLocator}
            borderRadius={borderRadius}
            className={className}
            mb={mb}
        />
    );
};

export { Button };
