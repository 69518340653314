// UpsaleContainer
export const UPSALE_2 = 'upsale_2';
export const UPSELL_3 = 'upsell_3';
export const UPSELL_SUB = 'upsell_sub_course_tech';
export const UPSELL_COACH = 'upsell_coach';

// Noom flow
export const NOOM_FLOW_PAYMENT_ID = 'payment_E_checkout_Echo1';
export const NOOM_FLOW_COUNT_PRODUCTS = 28;

// Short Noom flow
export const SHORT_NOOM_FLOW_PAYMENT_ID = 'payment_Ea_checkout_Echo1';
export const SHORT_NOOM_FLOW_COUNT_PRODUCTS = 4;

// Two Product Noom flow
export const TWO_PRODUCT_NOOM_FLOW_PAYMENT_ID = 'payment_Eb_checkout_Echo1';
export const TWO_PRODUCT_NOOM_FLOW_COUNT_PRODUCTS = 8;

// Introductory flow
export const INTRODUCTORY_A_FLOW_PAYMENT_ID = 'payment_introductory_a';
export const INTRODUCTORY_B_FLOW_PAYMENT_ID = 'payment_introductory_b';

// Introductory scroll flow
export const INTRODUCTORY_SCROLL_PAYMENT_ID = 'payment_Bh_checkout_Bravo1';

// Unimeal flow
export const UNIMEAL_FLOW_PAYMENT_ID = 'payment_D1_checkout_Charlie1';
export const UNIMEAL_FLOW_COUNT_PRODUCTS = 2;

// Trust pilot flow
export const TRUST_PILOT_A_PAYMENT_ID = 'payment_B8_checkout_Bravo1_a';
export const TRUST_PILOT_B_PAYMENT_ID = 'payment_B8_checkout_Bravo1_b';

// Video payment flow
export const VIDEO_PAYMENT_ID = 'payment_B5_checkout_Bravo1';

// Payment with trial and simple products
export const PAYMENT_BF = 'payment_Bf';
export const PAYMENT_FB = 'payment_Fb';
