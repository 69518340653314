import { IConvertConstants } from './types';

export const DEFAULT_MEASUREMENT: IConvertConstants = {
    defaultCurrentWeight: 65,
    defaultAge: 35,
    defaultHeight: 162,
    defaultFitnessLevel: 'moderatelyActive',
    activityLevelLitters: {
        1.2: 0,
        1.375: 0.15,
        1.55: 0.4,
        1.725: 0.68,
        1.9: 1.02,
    },
    fitnessLvlToActivityLvl: {
        sedentary: 1.2,
        lightlyActive: 1.375,
        moderatelyActive: 1.55,
        veryActive: 1.725,
        extraActive: 1.9,
    },
};
