import { ERROR_LEVELS, SENTRY_APP } from 'sentry-utils';
import { put, takeLatest } from 'redux-saga/effects';

import { getPlanDynamicConfigs } from 'api/components/Onboarding';

import { GET_PLAN_DYNAMIC_CONFIGS } from 'redux/Onboadring/actionTypes';
import { setDynamicConfigs } from 'redux/Onboadring/actions';

import sentry from 'services/Sentry/SentryInstance';

import { getLanguage } from 'helpers/utils';

import { ResponsePlanDynamicConfigs } from 'types/onboarding/dynamicConfig';

function* dynamicConfigs() {
    const language = getLanguage();

    try {
        const data: ResponsePlanDynamicConfigs = yield getPlanDynamicConfigs({ headers: { language } });

        if (!data.data) {
            sentry.logError(
                new Error('User can\'t get dynamicConfig DATA | Personalization Plan'),
                SENTRY_APP,
                ERROR_LEVELS.CRITICAL,
            );
        }

        yield put(setDynamicConfigs(data.data || []));
    } catch (error) {
        if (error?.code !== 'ERR_NETWORK') {
            sentry.logError(
                new Error('User can\'t get dynamicConfig | Personalization Plan'),
                SENTRY_APP,
                ERROR_LEVELS.CRITICAL,
                { info: error }
            );
        }

        console.error('error', error);
    }
}

export const planDynamicConfigs = [takeLatest(GET_PLAN_DYNAMIC_CONFIGS, dynamicConfigs)];
