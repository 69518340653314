import { DataProvider } from 'testania';
import { useHistory } from 'react-router-dom';
import { Trans } from 'react-i18next';
import React from 'react';

import { getProjectEmail } from 'helpers/settings';

import { Button, Text } from 'libComponents';
import Modal from 'components/Modal';

import styles from './PurchaseErrorModal.module.css';

import { ReactComponent as AlertIcon } from './img/warning.svg';

type PurchaseErrorModalProps = {
    isOpen: boolean;
    productCode: string;
    closeHandler: () => void;
};

function PurchaseErrorModal({ isOpen, productCode, closeHandler }: PurchaseErrorModalProps) {
    const history = useHistory();

    const handleCreateAccount = async () => {
        const finishPath = DataProvider.getPathTestaniaPaidFinish();

        history.push(finishPath);

        closeHandler();
    };

    // TODO: remove in next release (add it to translations)
    let productName = productCode;

    switch (productCode) {
        case 'guide_cardio':
            productName = 'fat_burning_gd_full';

            break;
        case 'guide_nutrition':
            productName = 'weight_loss_gd_full';

            break;
        case 'guide_all':
            productName = 'fat_burning_gd__weight_loss_gd_full';
    }

    return (
        <Modal isOpen={isOpen} onClose={closeHandler} isTransparency contentSize desktopLargeWidth withClose>
            <div className={styles.contentWrapper}>
                <AlertIcon className={styles.alertIcon} />
                <Text type="h5" center mb={12}>
                    <Trans i18nKey="modals.purchaseErrorModal.title" />
                </Text>
                <Text color="text-secondary" mb={30} className={styles.description}>
                    <Trans
                        i18nKey="modals.purchaseErrorModal.description"
                        values={{ productName }}
                        components={{ 1: <span className={styles.descriptionName} /> }}
                    />
                </Text>
                <Button onClick={handleCreateAccount} mb={20}>
                    <Trans i18nKey="common.createAccount" />
                </Button>
                <a href={`mailto:${getProjectEmail()}`} className={styles.link}>
                    <Trans i18nKey="common.contactSupport" />
                </a>
            </div>
        </Modal>
    );
}

export default PurchaseErrorModal;
