import config from 'config';

const yahooPush = (qstrings: { et: string; ea: string; gv?: string }) => {
    window.dotq = window.dotq || [];
    window.dotq.push({
        projectId: config.YAHOO_PROJECT_ID,
        properties: {
            pixelId: config.YAHOO_PIXEL_ID,
            qstrings,
        },
    });
};

export default yahooPush;
