import { setShortFlow, setSkipUpsellCoach } from 'redux/Payment/actions';

import {
    NOOM_FLOW_PAYMENT_ID,
    SHORT_NOOM_FLOW_PAYMENT_ID,
    INTRODUCTORY_A_FLOW_PAYMENT_ID,
    INTRODUCTORY_B_FLOW_PAYMENT_ID,
    TWO_PRODUCT_NOOM_FLOW_PAYMENT_ID,
    UNIMEAL_FLOW_PAYMENT_ID,
    TRUST_PILOT_A_PAYMENT_ID,
    TRUST_PILOT_B_PAYMENT_ID,
    VIDEO_PAYMENT_ID,
    INTRODUCTORY_SCROLL_PAYMENT_ID,
    UPSALE_2,
    UPSELL_3,
    PAYMENT_BF,
    PAYMENT_FB,
    UPSELL_SUB,
    UPSELL_COACH,
} from '../constants/payments';

import { getLanguage } from 'helpers/utils';

import {
    getConfigValidator,
    getIntroductoryFlowValidator,
    getNoomFlowValidator,
    getPaymentWithDifferentProductTypeFlowValidator,
    getShortNoomFlowValidator,
    getUnimealFlowValidator,
    getUpsellValidator,
    getTwoProductNoomFlowValidator,
    getObConfigValidator,
    getObUnfortunatelyValidator,
    getUpsellSubscriptionValidator,
} from '../validators/flowValidators';

export const validateFlow = ({ config, dispatch, country, currentFlowName, setCurrentFlowName }) => {
    let status = true;

    const language = getLanguage();

    const { payment_flow, onboarding_flow } = config;

    // TODO: Bad naming, rename 'check-' to 'find-'
    const checkFlow = (flowId) => payment_flow.find((item) => item.id === flowId);
    const checkObFlow = (flowId) => onboarding_flow.find((item) => item.id === flowId);

    const runner = (rules) => {
        for (const rule of rules) {
            const errorMessage = '%c Flow validation error - ' + rule.message;

            if (!rule.exec(rule.args)) {
                status = false;

                // for case if error on flow, and default flow include target page
                dispatch(setShortFlow(false));

                /* eslint-disable no-console */
                console.log(errorMessage, 'color:red;');

                break;
            }
        }
    };

    const runConfig = () => {
        const validator = getConfigValidator(config);

        runner(validator);
    };

    const runFlow = (flow, flowName, flowValidator, withConfig) => {
        setCurrentFlowName(flowName);

        const { products, config } = flow;

        const validator = flowValidator(products, withConfig && config);

        if (status) runner(validator);
    };

    const runObFlow = (flow, flowName, flowValidator) => {
        setCurrentFlowName(flowName);

        const validator = flowValidator(flow);

        if (status) runner(validator);
    };

    runConfig();

    // Flow with upsell
    const withUpsell2 = checkFlow(UPSALE_2);
    const withUpsell3 = checkFlow(UPSELL_3);
    const withUpsellSub = checkFlow(UPSELL_SUB);
    const withUpsellCoach = checkFlow(UPSELL_COACH);

    // Noom flow
    const isShortNoomFlow = checkFlow(SHORT_NOOM_FLOW_PAYMENT_ID);
    const isNoomFlow = checkFlow(NOOM_FLOW_PAYMENT_ID);
    const isUnimealFlow = checkFlow(UNIMEAL_FLOW_PAYMENT_ID);
    const isTwoProductNoomFlow = checkFlow(TWO_PRODUCT_NOOM_FLOW_PAYMENT_ID);

    // Introductory flow
    const isIntroductoryAPage = checkFlow(INTRODUCTORY_A_FLOW_PAYMENT_ID);
    const isIntroductoryBPage = checkFlow(INTRODUCTORY_B_FLOW_PAYMENT_ID);
    const isIntroductoryFlow = isIntroductoryAPage && isIntroductoryBPage;
    const isShortIntroductoryFlow = isIntroductoryAPage && !isIntroductoryBPage;

    // Introductory scroll flow
    const isIntroductoryScrollAPage = checkFlow(INTRODUCTORY_SCROLL_PAYMENT_ID);
    const isIntroductoryScrollBPage = checkFlow(INTRODUCTORY_B_FLOW_PAYMENT_ID);
    const isIntroductoryScrollFlow = isIntroductoryScrollAPage && isIntroductoryScrollBPage;
    const isShortIntroductoryScrollFlow = isIntroductoryScrollAPage && !isIntroductoryScrollBPage;

    // TrustPilot Flow
    const isTrustPilotAPage = checkFlow(TRUST_PILOT_A_PAYMENT_ID);
    const isTrustPilotBPage = checkFlow(TRUST_PILOT_B_PAYMENT_ID);
    const isTrustPilotFlow = isTrustPilotAPage && isTrustPilotBPage;
    const isShortTrustPilotFlow = isTrustPilotAPage && !isTrustPilotBPage;

    // Video Payment Flow
    const isVideoPaymentAPage = checkFlow(VIDEO_PAYMENT_ID);
    const isVideoPaymentIntroductoryBPage = checkFlow(INTRODUCTORY_B_FLOW_PAYMENT_ID);
    const isVideoPaymentFlow = isVideoPaymentAPage && isVideoPaymentIntroductoryBPage;
    const isShortVideoPaymentFlow = isVideoPaymentAPage && !isVideoPaymentIntroductoryBPage;

    // Payment flow with trial and simple product
    const isPaymentBFFlow = checkFlow(PAYMENT_BF);
    const isPaymentFBFlow = checkFlow(PAYMENT_FB);

    // Onboarding Flow
    const obConfigFlows = ['ob_config_1', 'ob_config_2', 'ob_config_3'].map(checkObFlow).filter(Boolean);
    const obUnfortunatelyFlow = checkObFlow('ob_unfortunately');

    obConfigFlows.forEach((obConfigFlow) => {
        runObFlow(obConfigFlow, `Onboarding Config Screen ${obConfigFlow.id}`, getObConfigValidator);
    });

    if (obUnfortunatelyFlow) {
        runObFlow(obUnfortunatelyFlow, 'Onboarding Unfortunately', getObUnfortunatelyValidator);
    }

    if (withUpsell2) {
        runFlow(withUpsell2, 'Upsell 2', getUpsellValidator);
    }

    if (withUpsell3) {
        runFlow(withUpsell3, 'Upsell 3', getUpsellValidator);
    }

    if (withUpsellSub) {
        runFlow(withUpsellSub, 'Upsell Sub', getUpsellSubscriptionValidator, true);
    }

    if (withUpsellCoach && (language !== 'en' || country !== 'US')) {
        dispatch(setSkipUpsellCoach(true));
    }

    if (isShortIntroductoryFlow || isShortTrustPilotFlow || isShortVideoPaymentFlow || isShortIntroductoryScrollFlow) {
        dispatch(setShortFlow(true));
    }

    // Noom flow
    if (isNoomFlow) runFlow(isNoomFlow, 'Noom', getNoomFlowValidator);

    if (isShortNoomFlow) runFlow(isShortNoomFlow, 'Short Noom flow', getShortNoomFlowValidator);

    if (isTwoProductNoomFlow) runFlow(isTwoProductNoomFlow, 'Two Product Noom flow', getTwoProductNoomFlowValidator);

    // Introductory flow
    if (isIntroductoryFlow) {
        runFlow(isIntroductoryAPage, 'IntroductoryA', getIntroductoryFlowValidator);
        runFlow(isIntroductoryBPage, 'IntroductoryB', getIntroductoryFlowValidator);
    }

    if (isShortIntroductoryFlow) {
        runFlow(isIntroductoryAPage, 'Short Introductory flow', getIntroductoryFlowValidator);
    }

    // Introductory scroll flow
    if (isIntroductoryScrollFlow) {
        runFlow(isIntroductoryScrollAPage, 'IntroductoryScrollA', getIntroductoryFlowValidator);
        runFlow(isIntroductoryScrollBPage, 'IntroductoryScrollB', getIntroductoryFlowValidator);
    }

    if (isShortIntroductoryScrollFlow) {
        runFlow(isIntroductoryScrollAPage, 'Short Introductory Scroll flow', getIntroductoryFlowValidator);
    }

    // TrustPilot flow
    if (isTrustPilotFlow) {
        runFlow(isTrustPilotAPage, 'TrustPilotAPage', getIntroductoryFlowValidator);
        runFlow(isTrustPilotBPage, 'TrustPilotBPage', getIntroductoryFlowValidator);
    }

    if (isShortTrustPilotFlow) {
        runFlow(isTrustPilotAPage, 'Short TrustPilot flow', getIntroductoryFlowValidator);
    }

    // Video Payment flow
    if (isVideoPaymentFlow) {
        runFlow(isVideoPaymentAPage, 'VideoAPage', getIntroductoryFlowValidator);
        runFlow(isVideoPaymentIntroductoryBPage, 'VideoBPage', getIntroductoryFlowValidator);
    }

    if (isShortVideoPaymentFlow) {
        runFlow(isVideoPaymentAPage, 'Short Video Payment flow', getIntroductoryFlowValidator);
    }

    // Unimeal Flow
    if (isUnimealFlow) runFlow(isUnimealFlow, 'Unimeal', getUnimealFlowValidator);

    if (isPaymentBFFlow) {
        runFlow(isPaymentBFFlow, 'isPaymentBFFlow', getPaymentWithDifferentProductTypeFlowValidator);
    }

    if (isPaymentFBFlow) {
        runFlow(isPaymentFBFlow, 'isPaymentFBFlow', getPaymentWithDifferentProductTypeFlowValidator);
    }

    return Promise.resolve({ name: `validate ${currentFlowName} flow`, status });
};
