import { PAYMENT_FLOW_PERIODS, PRODUCT_CODES } from 'constants/payments';

// utils
import { fromPennyToInt, getDiscount } from '../utils';

export const preparePriceData = ({ price, start_price }) => {
    const priceInt = fromPennyToInt(price);
    const fullPrice = fromPennyToInt(start_price);
    const save = getDiscount(fullPrice, priceInt);

    return { price: priceInt, fullPrice, save };
};

export const checkProductPeriods = ({ periodsArr }) => {
    return periodsArr.every((period) => PAYMENT_FLOW_PERIODS.includes(period));
};

export const checkSamePeriods = ({ periodsArr }) => {
    return new Set(periodsArr).size === 1;
};

export const checkProductsCount = ({ products, count }) => products.length === count;

export const checkLtv = ({ products }) => {
    return products.every((product) => typeof product?.ltv === 'number' && product?.ltv > 0);
};

export const checkMatchPeriodWithPrice = ({ arrWithPrices, products }) => {
    return arrWithPrices.every((price) => {
        const productsByPrice = products.filter((product) => product.price === price);
        const arrayWithPeriods = getArrayFromObjectByFieldName(productsByPrice, 'period');

        return !hasDuplicates(arrayWithPeriods);
    });
};

export const getArrayFromObjectByFieldName = (arrObjects, key) => {
    const resultArr = [];

    arrObjects.forEach((element) => {
        resultArr.push(element[key]);
    });

    return resultArr;
};

const hasDuplicates = (array) => {
    return new Set(array).size !== array.length;
};

export const checkPreselectedField = ({ products, withImportantOrder }) => {
    let productsList = products;

    if (withImportantOrder) {
        const averageProductValue = products.length / 2;

        productsList = products.slice(0, averageProductValue);
    }

    return productsList.some((product) => product.is_preselected === true);
};

export const checkValuePairsIdentityByFieldName = ({ products, fieldName }) => {
    const countValues = products.reduce((acc, curr) => {
        const fieldValue = curr[fieldName];

        acc[fieldValue] = (acc[fieldValue] || 0) + 1;

        return acc;
    }, {});

    return Object.values(countValues).every((value) => value === 2);
};

export const checkProductsDividedByTwo = ({ products }) => {
    return products.length % 2 === 0;
};

export const checkMatchValueByField = ({ products, fieldName }) => {
    const list = getArrayFromObjectByFieldName(products, fieldName);

    return [...new Set(list)].length === 1;
};

export const checkDifferentValuesByField = ({ products, fieldName, count }) => {
    const list = getArrayFromObjectByFieldName(products, fieldName);

    return [...new Set(list)].length === count;
};

export const checkProductsCode = ({ products }) => {
    return products.every((item) => PRODUCT_CODES.includes(item.name));
};

export const checkProductsConfig = (config) => {
    const keys = ['backgroundImg', 'courseTitle', 'courseDescription', 'disclaimerLogic'];
    const titles = [
        'own_dietician',
        'eat_mindfully',
        'eat_less',
        'intermittent_fasting',
        'healthy_living',
        'transform_health',
        'mindful_planning',
        'wellness_coach',
        'twellness_coach',
    ];
    const descriptions = [
        'best_foods',
        'calorie_sources',
        'own_plans',
        'professionals',
        'lifelong_results',
        'quality',
        'first_step',
        'calorie_intake',
        'mindful_eater',
        'right_number',
        'fasting_hours',
        'guesswork',
        'step_forward',
        'own_pace',
        'step_by_step',
        'maximize_results',
        'stay_on_track',
        'fasting_type',
    ];
    const disclaimers = ['regular', 'intro'];

    const hasAllKeys = keys.every((item) => {
        return config[item];
    });

    if (hasAllKeys) {
        const hasCorrectTitle = titles.includes(config.courseTitle);

        if (!hasCorrectTitle) return false;

        const hasCorrectDescription = config.courseDescription.split(',').every((item) => {
            return descriptions.includes(item.replace(/'/g, ''));
        });

        if (!hasCorrectDescription) return false;

        return disclaimers.includes(config.disclaimerLogic);
    }

    return false;
};

export const checkProductListByType = (productList) => {
    const sortedProductList = productList.reduce((acc, curr) => {
        const trialFieldValue = curr.period !== curr.trial;
        const introFieldValue = curr.period === curr.trial;

        if (trialFieldValue) {
            acc['trialProduct'] = (acc['trialProduct'] || 0) + 1;
        }

        if (introFieldValue) {
            acc['introProduct'] = (acc['introProduct'] || 0) + 1;
        }

        return acc;
    }, {});

    return sortedProductList.trialProduct === 1 && sortedProductList.introProduct === 2;
};

export const checkDifferentTypeOfProduct = ({ products }) => {
    const averageProductValue = products.length / 2;

    const productsWithDiscount = products.filter((product, i) => i < averageProductValue);
    const productsWithoutDiscount = products.filter((product, i) => i >= averageProductValue);

    const isValidListWithDiscount = checkProductListByType(productsWithDiscount);
    const isValidListWithoutDiscount = checkProductListByType(productsWithoutDiscount);

    return isValidListWithDiscount && isValidListWithoutDiscount;
};
