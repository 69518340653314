import {
    OnboardingPages,
    OtherPages,
    PaymentPages,
    StartPages,
    ResultPages,
    UpsellPages,
} from 'router/routesMap/publicRoutesMap';

import { ANALYTICS_NAME_LIST } from 'constants/analytics';

import { replaceUnderscore, getArrayFromString } from 'helpers/utils';
import { isEmptyValue } from 'helpers/root/utils';

const validationConfigRegExp = new RegExp('^[A-Za-z0-9_]+$');

const PUBLIC_ROUTES = [
    ...StartPages,
    ...OnboardingPages,
    ...PaymentPages,
    ...UpsellPages,
    ...ResultPages,
    ...OtherPages,
];

const checkFlow = (flow) => {
    if (Array.isArray(flow)) {
        for (const { id } of flow) {
            if (!validationConfigRegExp.test(id)) {
                return false;
            }
        }
    } else {
        return validationConfigRegExp.test(flow?.id);
    }

    return true;
};

const checkIds = (pagesData) => {
    const routesMap = [...PUBLIC_ROUTES];

    for (const { id } of pagesData) {
        if (!routesMap.some(({ route }) => route === replaceUnderscore(id))) {
            /* eslint-disable no-console */
            console.log(`%c Invalid page id: ${id}`, 'color:red;');

            return false;
        }
    }

    return true;
};

export const checkConfig = (config) => {
    const isValidStartFlow = checkFlow(config?.start_page);
    const isValidOnboardingFlow = checkFlow(config?.onboarding_flow);
    const isValidPaymentFlow = checkFlow(config?.payment_flow);

    return isValidStartFlow && isValidOnboardingFlow && isValidPaymentFlow;
};

export const checkPagesId = (config) => {
    const pagesData = [];

    config?.onboarding_flow && pagesData.push(...config?.onboarding_flow);
    config?.payment_flow && pagesData.push(...config?.payment_flow);
    !isEmptyValue(config?.start_page) && pagesData.push(config?.start_page);

    return checkIds(pagesData);
};

export const checkAnalyticsData = (config) => {
    const paymentFlow = config?.payment_flow;

    for (const { config } of paymentFlow) {
        if (config?.analytics) {
            const analyticsList = getArrayFromString(config.analytics);

            if (analyticsList.some((item) => !ANALYTICS_NAME_LIST.includes(item))) {
                return false;
            }
        }
    }

    return true;
};
