import { systemNames } from 'wikr-core-analytics';

import config from 'config';

import { ICookieAnalyticList } from 'types/analytics';

export const ANALYTICS_NAME_LIST = ['ga', 'twitter', 'pixel', 'pinterest', 'tiktok', 'snapchat', 'amazon'];

export const STRICTLY_NECESSARY_COOKIE = 'C0001';
export const PERFORMANCE_COOKIE = 'C0002';
export const FUNCTIONAL_COOKIE = 'C0003';
export const TARGETING_COOKIE = 'C0004';
export const SOCIAL_MEDIA_COOKIE = 'C0005';

// for now we don`t do any action for this action, it is pass only if all cookies was allowed on california banner
// we can toggle it in cookies settings - field "Sale of Personal Data"
export const CALIFORNIA_COOKIE = 'SPD_BG';

export const COOKIE_GROUP_LIST = [STRICTLY_NECESSARY_COOKIE, PERFORMANCE_COOKIE, FUNCTIONAL_COOKIE, TARGETING_COOKIE];

export const COOKIE_ANALYTIC_LIST: ICookieAnalyticList = {
    [STRICTLY_NECESSARY_COOKIE]: [],
    [PERFORMANCE_COOKIE]: [],
    [FUNCTIONAL_COOKIE]: [],
    [TARGETING_COOKIE]: [
        {
            name: 'ga',
            id: config.GOOGLE_ID,
            config: {
                googleAdsId: config.GOOGLE_ADS_ID,
                googleAdsShopId: config.GOOGLE_ADS_SHOP_ID,
            },
        },
        {
            name: 'ga4',
            id: config.GOOGLE_4_ID,
        },
        {
            name: 'twitter',
            id: config.TWITTER_ID,
        },
        {
            name: 'pixel',
            id: config.PIXEL_ID,
        },
        {
            name: 'pinterest',
            id: config.PINTEREST_ID,
        },
        {
            name: 'tiktok',
            id: config.TIKTOK_ID,
        },
        {
            name: 'snapchat',
            id: config.SNAPCHAT_ID,
        },
    ],
    [SOCIAL_MEDIA_COOKIE]: [],
};

export const DEFAULT_ACTIVE_ANALYTICS = [
    systemNames.pixel,
    systemNames.ga,
    systemNames.ga4,
    systemNames.amazon,
    systemNames.tiktok,
    systemNames.snapchat,
    systemNames.pinterest,
    systemNames.twitter,
];
