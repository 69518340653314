import React from 'react';

import { generatePolicyLink } from 'helpers/settings';

import styles from 'assets/styles/Common.module.css';

export const POLITICS = {
    DEFAULT: {
        TERMS_OF_USE: 'https://legal.fit-coach.io/page/terms-of-use',
        REFUND_POLICY: 'https://legal.fit-coach.io/page/refund-policy',
        PRIVACY_POLICY: 'https://legal.fit-coach.io/page/privacy-policy',
    },
    FASTING: {
        PRIVACY_POLICY: 'https://fasteasy.life/privacy-policy-app.html',
    },
    MIRROR: {
        TERMS_OF_USE: 'policy/mirror-terms-of-use.html',
        REFUND_POLICY: 'policy/mirror-privacy-policy.html',
        PRIVACY_POLICY: 'policy/mirror-refund-policy.html',
    },
};

/* eslint-disable jsx-a11y/anchor-has-content */
export const DISCLAIMER_LINKS = {
    anchorTerms: (
        <a
            className={styles.underline}
            href={generatePolicyLink(POLITICS.MIRROR.TERMS_OF_USE, POLITICS.DEFAULT.TERMS_OF_USE)}
            target="_blank"
            rel="noopener noreferrer"
            data-locator="termsOfUseLink"
        />
    ),
    anchorPrivacy: (
        <a
            className={styles.underline}
            href={generatePolicyLink(POLITICS.MIRROR.PRIVACY_POLICY, POLITICS.DEFAULT.PRIVACY_POLICY)}
            target="_blank"
            rel="noopener noreferrer"
            data-locator="privacyPolicyLink"
        />
    ),
    anchorRefund: (
        <a
            className={styles.underline}
            href={generatePolicyLink(POLITICS.MIRROR.REFUND_POLICY, POLITICS.DEFAULT.REFUND_POLICY)}
            target="_blank"
            rel="noopener noreferrer"
            data-locator="refundPolicyLink"
        />
    ),
};

export const COURSE_LINK = 'wellcube.pro';

export const DEFAULT_FAVICON_URL = '/favicons/favicon.png';

export const CABINET_DOMAIN = 'app.fit-coach.io';
