import React from 'react';
import classNames from 'classnames/bind';

import base from 'assets/styles/Common.module.css';
import styles from './Title.module.css';

const globalStyles = { ...styles, ...base };
const cx = classNames.bind(globalStyles);

function Title(props: any) {
    const {
        globalClassNames,
        children,
        h1,
        h2,
        h3,
        h4,
        h4New,
        h6,
        centered,
        white,
        colorFast,
        uppercase,
        greenSpan,
        className,
    } = props;

    // @ts-ignore
    const classes = globalClassNames.map((item) => ({ [item]: globalStyles[item] }));

    const titleClass = cx(
        {
            default: true,
            h1,
            h2,
            h3,
            h4,
            h4New,
            h6,
            centered,
            white,
            colorFast,
            uppercase,
            greenSpan,
        },
        classes,
        className
    );

    return <h1 className={titleClass}>{children}</h1>;
}

Title.defaultProps = {
    className: '',
    globalClassNames: [],
};

export default Title;
