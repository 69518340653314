export const updateGlobalLoaderVisibility = (isVisible: boolean) => {
    const globalLoader = document.getElementById('app-loader');

    if (globalLoader) globalLoader.style.display = isVisible ? 'block' : 'none';
};

// Workaround for window.open method since it doesn't work for Safari browser
export const windowOpen = (url: string, openInCurrentWindow = false) => (
    Object.assign(document.createElement('a'), {
        target: openInCurrentWindow ? '_self' : '_blank',
        rel: 'noopener noreferrer',
        href: url,
    }).click()
);

