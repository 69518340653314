// TODO: Our Container needs to be rewrited..
import React from 'react';
import classNames from 'classnames/bind';

import base from 'assets/styles/Common.module.css';
import styles from './Container.module.css';

import { IContainerProps } from './types';

const globalStyles: any = { ...styles, ...base };
const cx = classNames.bind(globalStyles);

const Container: React.FC<IContainerProps> = ({
    globalClassNames = [],
    children,
    full,
    noBottomPadding,
    solo,
    staticHeader,
}) => {
    const classes = globalClassNames.map((item) => ({ [item]: globalStyles[item] }));

    const containerClass = cx(
        {
            default: true,
            withoutHeader: solo,
            fullPadding: full,
            noBottomPadding,
            staticHeader,
        },
        classes
    );

    return <div className={containerClass}>{children}</div>;
};

export default Container;
