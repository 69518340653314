import config from 'config';

import { IApiClientProps } from './types/api';

import ApiClientMethods from './ApiClientMethods';

export default class ApiClient {
    public: ApiClientMethods;
    private: ApiClientMethods;

    constructor({ apiUrl = config.API_URL, onError }: IApiClientProps) {
        this.public = new ApiClientMethods({ apiUrl, onError });
        this.private = new ApiClientMethods({ apiUrl, isPrivate: true, onError });
    }
}
