/* eslint-disable max-lines */
import {
    ValueWithMeasuringSystem,
    MeasuringSystemType,
    IOnboardingScreensList,
    OccasionList,
    UsersAmountDataType,
    SkipOnboarding,
} from 'types/onboarding/onboarding';
import { IGender } from 'types/commonInterfaces';

import * as actionTypes from './actionTypes';

export function setUrlParams(url: { [key: string]: string } | null) {
    return {
        type: actionTypes.SET_URL_PARAMS,
        payload: url,
    };
}

export function setGender(gender: IGender | string | null) {
    return {
        type: actionTypes.SET_GENDER,
        payload: gender,
    };
}

export function setGoal(goal: string | null | undefined) {
    return {
        type: actionTypes.SET_GOAL,
        payload: goal,
    };
}

export function setDiet(diet: string) {
    return {
        type: actionTypes.SET_DIET,
        payload: diet,
    };
}

export function setLastPerfectWeight(weight: string) {
    return {
        type: actionTypes.SET_LAST_PERFECT_WEIGHT,
        payload: weight,
    };
}

export function setSleepDuration(sleepDuration: string) {
    return {
        type: actionTypes.SET_SLEEP_DURATION,
        payload: sleepDuration,
    };
}

export function setPsychoPower(psychoPower: boolean) {
    return {
        type: actionTypes.SET_PSYCHO_POWER,
        payload: psychoPower,
    };
}

export function setPsychoBusy(psychoBusy: boolean) {
    return {
        type: actionTypes.SET_PSYCHO_BUSY,
        payload: psychoBusy,
    };
}

export function setPreferenceEat(preferenceEat: string[]) {
    return {
        type: actionTypes.SET_PREFERENCE_EAT,
        payload: preferenceEat,
    };
}

export function setFeelBreak(feelBreak: string) {
    return {
        type: actionTypes.SET_FEEL_BREAK,
        payload: feelBreak,
    };
}

export function setWaterConsumption(waterConsumption: string) {
    return {
        type: actionTypes.SET_WATER_CONSUMPTION,
        payload: waterConsumption,
    };
}

export function setCurrentBody(body_type: string | null) {
    return {
        type: actionTypes.SET_CURRENT_BODY,
        payload: body_type,
    };
}

export function setCurrentWeight({ unit = 'METRIC', value }: ValueWithMeasuringSystem) {
    return {
        type: actionTypes.SET_CURRENT_WEIGHT,
        payload: { unit, value },
    };
}

export function setTargetBody(targetBody: string | null) {
    return {
        type: actionTypes.SET_TARGET_BODY,
        payload: targetBody,
    };
}

export function setTargetZone(targetZone: string[]) {
    return {
        type: actionTypes.SET_TARGET_ZONE,
        payload: targetZone,
    };
}

export function setTargetWeight({ unit = 'METRIC', value }: ValueWithMeasuringSystem) {
    return {
        type: actionTypes.SET_TARGET_WEIGHT,
        payload: { unit, value },
    };
}

export function setTall({ unit = 'METRIC', value }: ValueWithMeasuringSystem) {
    return {
        type: actionTypes.SET_TALL,
        payload: { unit, value },
    };
}

export function setMeasure(system: MeasuringSystemType) {
    return {
        type: actionTypes.SET_MEASURE_SYSTEM,
        payload: system,
    };
}

export function setTrainingLevel(training_level: string) {
    return {
        type: actionTypes.SET_TRAINING_LEVEL,
        payload: training_level,
    };
}

export function setActivityLevel(activity_level: string) {
    return {
        type: actionTypes.SET_ACTIVITY_LEVEL,
        payload: activity_level,
    };
}

export function setAge(age: string | number) {
    return {
        type: actionTypes.SET_AGE,
        payload: age,
    };
}

export function setAgeRange(data: Record<string, string | number>) {
    return {
        type: actionTypes.SET_AGE_RANGE,
        payload: data,
    };
}

export function setOnboardingScreensList(screensList: IOnboardingScreensList) {
    return {
        type: actionTypes.SET_ONBOARDING_SCREENS_LIST,
        payload: screensList,
    };
}

export function initAutoLogin() {
    return {
        type: actionTypes.INIT_AUTO_LOGIN,
    };
}

export function setName(name: string) {
    return {
        type: actionTypes.SET_NAME,
        payload: name,
    };
}

export function setOccasion(occasionList: OccasionList) {
    return {
        type: actionTypes.SET_OCCASION,
        payload: occasionList,
    };
}

export function setOccasionDate(occasionDate: string) {
    return {
        type: actionTypes.SET_OCCASION_DATE,
        payload: occasionDate,
    };
}

export function setUsersAmountData(amountData: UsersAmountDataType) {
    return {
        type: actionTypes.SET_USERS_AMOUNT_DATA,
        payload: amountData,
    };
}

export function skipOnboarding(payload: SkipOnboarding) {
    return {
        type: actionTypes.SKIP_ONBOARDING,
        payload: payload,
    } as const;
}

export function setGoalId(payload: number) {
    return {
        type: actionTypes.SET_GOAL_ID,
        payload,
    };
}

export function setExtraWorkoutType(payload: any[]) {
    return {
        type: actionTypes.SET_EXTRA_WORKOUT_TYPE,
        payload,
    };
}

export function getExtraWorkoutType() {
    return {
        type: actionTypes.GET_EXTRA_WORKOUT_TYPE,
    };
}

export function setDynamicConfigs(payload: any[]) {
    return {
        type: actionTypes.SET_DYNAMIC_CONFIGS,
        payload,
    };
}

export function getPlanDynamicConfigs() {
    return {
        type: actionTypes.GET_PLAN_DYNAMIC_CONFIGS,
    };
}

export function setStepTracker(payload: boolean) {
    return {
        type: actionTypes.SET_STEP_TRACKER,
        payload,
    };
}

export function setTrainingDays(payload: Array<number>) {
    return {
        type: actionTypes.SET_TRAINING_DAYS,
        payload,
    };
}

export function setBodyParts(payload: string[]) {
    return {
        type: actionTypes.SET_BODY_PARTS,
        payload,
    };
}

export function setEquipment(payload: string[]) {
    return {
        type: actionTypes.SET_EQUIPMENT,
        payload,
    };
}

export function setWorkoutType(payload: string[]) {
    return {
        type: actionTypes.SET_USER_WORKOUT_TYPE,
        payload,
    } as const;
}
