import { ERROR_LEVELS, SENTRY_ONE_TRUST } from 'sentry-utils';
import { useEffect, useState } from 'react';

import sentry from 'services/Sentry/SentryInstance';
import { OneTrust } from 'services/OneTrust';

const DEFAULT_CONFIG = { childList: true, subtree: true };

interface IOneTrust {
    init: () => void;
    allowAll: () => void;
}

export const useOneTrustLoaded = (selector = 'onetrust-consent-sdk') => {
    const [isOneTrustLoaded, setIsOneTrustLoaded] = useState(false);
    const [oneTrust, setOneTrust] = useState<IOneTrust | null>(null);
    const [isOneTrustFail, setIsOneTrustFail] = useState(false);

    new Promise(() => {
        const isOneTrustError = localStorage.getItem('isOneTrustError');

        if (isOneTrustError && !isOneTrustFail) {
            setIsOneTrustFail(true);

            sentry.logError(new Error(isOneTrustError), SENTRY_ONE_TRUST, ERROR_LEVELS.CRITICAL, {
                host: window.location.host,
                href: window.location.href,
            });

            return;
        }

        if (document.getElementById(selector) && !isOneTrustLoaded) {
            setIsOneTrustLoaded(true);
        }

        new MutationObserver((_, observer) => {
            if (document.getElementById(selector) && !isOneTrustLoaded) {
                setIsOneTrustLoaded(true);

                observer.disconnect();
            }
        }).observe(document.body, DEFAULT_CONFIG);
    });

    useEffect(() => {
        if (isOneTrustLoaded) {
            const { OneTrust: instance } = window;

            setOneTrust(new OneTrust(instance));
        }
    }, [isOneTrustLoaded]);

    useEffect(() => {
        if (oneTrust) {
            oneTrust?.init();
        }
    }, [oneTrust]);

    return { isOneTrustLoaded, oneTrust };
};

export default useOneTrustLoaded;
