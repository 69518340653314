import ReactModal from 'react-modal';
import React, { useEffect } from 'react';
import classNames from 'classnames/bind';

import modalLightStyles from './ModalLight.module.css';
import modalStyles from './Modal.module.css';

import ModalClose from '../ModalClose/ModalClose';

type Props = {
    children?: React.ReactNode;
    isOpen?: boolean;
    withClose?: boolean;
    onClose?: () => void;
    shouldCloseOnOverlayClick?: boolean;
    isLeftClose?: boolean;
    contentSize?: boolean;
    isFlex?: boolean;
    isSmallClose?: boolean;
    isShowModal?: boolean;
    isTransparency?: boolean;
    withOpacity?: boolean;
    desktopLargeWidth?: boolean;
    isExclusiveOffer?: boolean;
    paddingTop32?: boolean;
    paddingTop40?: boolean;
    paddingTop78?: boolean;
    noOverflow?: boolean;
    isFixedClose?: boolean;
    isLightTheme?: boolean;
    isPopover?: boolean;
    contentHeader?: React.ReactNode;
    overlayClassName?: string;
    checkoutModal?: boolean;
    modalClassName?: string;
};

function Modal(props: Props) {
    const {
        isOpen = false,
        withClose = true,
        shouldCloseOnOverlayClick = true,
        onClose = false,
        isLeftClose = false,
        contentSize = false,
        children,
        isTransparency,
        isExclusiveOffer,
        paddingTop32,
        paddingTop40,
        paddingTop78,
        desktopLargeWidth,
        isSmallClose,
        noOverflow,
        isFixedClose,
        isFlex,
        isLightTheme,
        isPopover,
        withOpacity,
        isShowModal,
        contentHeader,
        overlayClassName,
        checkoutModal,
        modalClassName,
    } = props;

    useEffect(() => {
        ReactModal.setAppElement('#app');
    }, []);

    const closeModal = () => {
        onClose && onClose();
    };

    const stylesForModal = isLightTheme ? modalLightStyles : modalStyles;

    const cx = classNames.bind(stylesForModal);
    const contentStyles = cx(
        {
            ReactModal__Content: true,
            ReactModal__Content__withTransparency: isTransparency,
            ReactModal__Content__exclusiveOffer: isExclusiveOffer,
            ReactModal__Content__paddingTop32: paddingTop32,
            ReactModal__Content__paddingTop40: paddingTop40,
            ReactModal__Content__paddingTop78: paddingTop78,
            ReactModal__Content__noOverflow: noOverflow,
            ReactModal__Content__flex: isFlex,
            ReactModal__Content__withPopover: isPopover,
            ReactModal__Content__contentSize: contentSize,
            ReactModal__Content__desktop: !isExclusiveOffer,
            ReactModal__Content__deskop__w432: desktopLargeWidth,
            ReactModal__Content__checkoutModal: checkoutModal,
        },
        modalClassName
    );
    const overlayStyles = cx(
        {
            ReactModal__Overlay__isClose: withOpacity && !isShowModal,
            ReactModal__Overlay__isOpen: withOpacity && isShowModal,
            ReactModal__Overlay: true,
            ReactModal__Overlay__withTransparency: isTransparency,
        },
        overlayClassName
    );
    const bodyStyles = cx({
        ReactModal__Body__isClose: withOpacity && !isShowModal,
        ReactModal__Body__isOpen: withOpacity && isShowModal,
    });

    return (
        <ReactModal
            shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
            shouldCloseOnEsc={false}
            isOpen={isOpen}
            onRequestClose={closeModal}
            contentLabel="Modal"
            className={contentStyles}
            overlayClassName={overlayStyles}
            bodyOpenClassName={bodyStyles}
        >
            {contentHeader}
            {onClose && withClose && (
                <ModalClose
                    onClick={closeModal}
                    isLeftClose={isLeftClose}
                    isSmallClose={isSmallClose}
                    isFixed={isFixedClose}
                />
            )}
            {children}
        </ReactModal>
    );
}

export default Modal;
