import { NOTIFY_STATUS } from 'constants/values';

export const GENERAL_ERROR_TOAST_MESSAGE = {
    message: 'Something went wrong',
    status: NOTIFY_STATUS.ERROR,
};

export const GENERAL_SUCCESS_TOAST_MESSAGE = {
    message: 'Changes successfully applied',
    status: NOTIFY_STATUS.SUCCESS,
};
