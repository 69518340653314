import { lazy } from 'react';

const lazyWithRetry = (componentImport: () => Promise<any>) =>
    lazy(async () => {
        const isAlreadyReload = JSON.parse(localStorage.getItem('page-is-already-reload') || 'false');

        try {
            const component = await componentImport();

            localStorage.setItem('page-is-already-reload', 'false');

            return component;
        } catch (error) {
            if (!isAlreadyReload) {
                setTimeout(() => location.reload(), 500);

                return localStorage.setItem('page-is-already-reload', 'true');
            }

            throw error;
        }
    });

export default lazyWithRetry;
