import { ResponseExtraWorkoutTypes } from 'types/onboarding/extraWorkoutType';
import { ResponsePlanDynamicConfigs } from 'types/onboarding/dynamicConfig';

import getPlanDynamicConfigsMutator from '../customInstance';
import getExtraWorkoutTypeMutator from '../customInstance';

type SecondParameter<T extends (...args: any) => any> = T extends (config: any, args: infer P) => any ? P : never;

export const getPlanDynamicConfigs = (options?: SecondParameter<typeof getPlanDynamicConfigsMutator>) => {
    return getPlanDynamicConfigsMutator<ResponsePlanDynamicConfigs>(
        { url: '/plan-dynamic-configs', method: 'get', timeout: 7000 },
        options
    );
};

export const getExtraWorkoutType = (
    params?: {
        gender?: string;
        goal_id?: string;
    },
    options?: SecondParameter<typeof getExtraWorkoutTypeMutator>
) => {
    return getExtraWorkoutTypeMutator<ResponseExtraWorkoutTypes>(
        { url: '/extra-workout-type', method: 'get', params },
        options
    );
};
