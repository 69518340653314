import { InitPayPal } from 'solid-payment/src/interfaces/payment';
import Solid from 'solid-payment';
import { SENTRY_PAYMENT, ERROR_LEVELS } from 'sentry-utils';
import { takeLatest, put, select } from 'redux-saga/effects';

import { RootState } from 'redux/rootReducer';
import { selectCurrentProduct } from 'redux/Payment/selectors';
import * as actionTypes from 'redux/Payment/actionTypes';

import sentry from 'services/Sentry/SentryInstance';

import { CurrentProduct } from 'types/payments/payments';
import { PaymentData } from 'types/payments/paymentApiInterfaces';
import { ActionType } from 'types/commonInterfaces';

export const getCurrentProduct = (state: RootState) => selectCurrentProduct(state);

export function* init({ payload }: ActionType<InitPayPal>) {
    // think about fix this case with another way | https://app.asana.com/0/1202352529812330/1203091595393043/f
    const currentProduct: CurrentProduct = yield select(getCurrentProduct);

    const initPayment = Object.assign({}, currentProduct, payload);

    yield put({ type: actionTypes.SET_LOADING_STATUS, payload: true });

    try {
        const response: PaymentData = yield Solid.init('payPal', initPayment);

        yield put({ type: actionTypes.SET_PAYMENT_DATA, payload: response });
        yield put({ type: actionTypes.SET_ORDER_ID, payload: response?.order?.order_id });
    } catch (e) {
        console.error('Error init');

        sentry.logError(e, SENTRY_PAYMENT, ERROR_LEVELS.CRITICAL, {
            ...payload,
        });
    }
}

export const initPayPal = [takeLatest(actionTypes.INIT_PAY_PAL, init)];
