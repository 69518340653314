import { AxiosRequestConfig, AxiosResponse } from 'axios';

import { apiClient } from './mobileApiClient';

export default async <T>(config: AxiosRequestConfig, options?: AxiosRequestConfig): Promise<T> => {
    const { method, url = '', params, headers } = { ...config, ...(options || {}) };

    switch (method) {
        case 'get': {
            const response: AxiosResponse = await apiClient.get(url, params, headers);

            return response.data as T;
        }
        default:
            throw new Error('Method not supported');
    }
};
