import { Trans } from 'react-i18next';
import React from 'react';

import { getProjectEmail } from 'helpers/settings';

import { Button, Text } from 'libComponents';
import Modal from 'components/Modal';

import styles from './GeneralErrorModal.module.css';

import icon from './img/icon.webp';

type Props = {
    isOpenModal: boolean;
    closeHandler: () => void;
};

function GeneralErrorModal(props: Props) {
    const { isOpenModal, closeHandler } = props;

    const openContactUsLink = () => window.open(`mailto:${getProjectEmail()}`, '_blank', 'noopener,noreferrer');

    return (
        <Modal
            withClose
            isLeftClose
            isFlex
            paddingTop32
            isSmallClose
            onClose={closeHandler}
            isOpen
            isShowModal={isOpenModal}
            isTransparency
            withOpacity
            desktopLargeWidth
        >
            <div className={styles.wrapper}>
                <img src={icon} className={styles.icon} alt="errorIcon" />
                <Text type="h1" color="text-secondary" center mb={20}>
                    <Trans i18nKey="modals.generalErrorModal.title" />
                </Text>
                <Text mb={40}>
                    <Trans>Something went wrong</Trans>.
                </Text>
                <Button text="Contact Us" onClick={openContactUsLink} mb={44} />
            </div>
        </Modal>
    );
}

export default GeneralErrorModal;
