import React from 'react';
import classNames from 'classnames/bind';

import base from 'assets/styles/Common.module.css';
import styles from './Text.module.css';

import { TextProps } from './types';

const globalStyles: any = { ...styles, ...base };
const cx = classNames.bind(globalStyles);

function Text(props: TextProps) {
    const {
        globalClassNames,
        children,
        medium,
        extraSmall,
        mediumSmall,
        mediumSmall2,
        small,
        bold,
        normal,
        caption,
        transparence,
        grey,
        centered,
        colorFast,
        withGreyBg,
        small2,
        white,
        uppercase,
        letterSpacing1,
        letterSpacing2,
        safeLight,
        red,
        underlineChild,
        orange,
        baseColor,
        lineThrough,
    } = props;

    const classes = globalClassNames?.map((item: any) => ({ [item]: globalStyles[item] }));

    const textClass = cx(
        {
            default: true,
            medium,
            extraSmall,
            mediumSmall,
            mediumSmall2,
            small,
            caption,
            grey,
            bold,
            normal,
            transparence,
            centered,
            colorFast,
            withGreyBg,
            small2,
            white,
            uppercase,
            letterSpacing1,
            letterSpacing2,
            safeLight,
            red,
            underlineChild,
            orange,
            baseColor,
            lineThrough,
        },
        classes
    );

    return <p className={textClass}>{children}</p>;
}

Text.defaultProps = {
    globalClassNames: [],
};

export default Text;
