import { ERROR_LEVELS, SENTRY_AXIOS } from 'sentry-utils';

import appConfig from 'config';

import sentry from 'services/Sentry/SentryInstance';

import { ClientError, initApiClient } from 'welltech-web-api-client';

const apiClient = initApiClient({
    apiUrl: appConfig.API_URL_MOBILE,
    apiVersion: appConfig.API_VERSION_MOBILE,
    apiKey: appConfig.API_KEY_MOBILE,
    authTokenKey: appConfig.MOBILE_TOKEN_KEY,
    extendResponse: true,
    onUpdateToken: (token: string) => {
        localStorage.setItem(appConfig.MOBILE_TOKEN_KEY, token);
    },
    onRetry: (retryCount, error, requestConfig) => {
        sentry.logError(new Error(`RETRY: ${error.message}`), SENTRY_AXIOS, ERROR_LEVELS.WARNING, {
            ...requestConfig,
        });
    },
    errorHandler: (error: ClientError) => {
        const response = error?.response;
        const request = error?.request;
        const errorStatus = response?.status;

        const extras = {
            requestURL: request?.requestURL,
            requestHeaders: request?.requestHeaders,
            requestData: request?.requestData,
            responseData: response?.data,
            responseHeaders: response?.headers,
        };

        const level = Number(errorStatus) >= 500 ? ERROR_LEVELS.CRITICAL : ERROR_LEVELS.ERROR;

        sentry.logError(new Error(error.message), SENTRY_AXIOS, level, extras);
    },
});

export { apiClient };
