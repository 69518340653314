import { updateGlobalLoaderVisibility } from 'helpers/actions/utils';

import * as actionTypes from './actionTypes';

export function hideHeader(status: boolean) {
    return {
        type: actionTypes.HIDE_HEADER,
        payload: status,
    };
}

export function hideProgressBar(status: boolean) {
    return {
        type: actionTypes.HIDE_PROGRESS_BAR,
        payload: status,
    };
}

export function showModal(payload: boolean) {
    return {
        type: actionTypes.SHOW_MODAL,
        payload: payload,
    };
}

export function hideModal() {
    return {
        type: actionTypes.HIDE_MODAL,
    };
}

export function setAppLoader(loaderState: boolean) {
    updateGlobalLoaderVisibility(loaderState);

    return {
        type: actionTypes.SET_LOADER_STATE,
        payload: loaderState,
    };
}

export function initFrontChat(status: boolean) {
    return {
        type: actionTypes.INIT_FRONT_CHAT,
        payload: status,
    };
}

export function setInlineTheme(theme: object) {
    return {
        type: actionTypes.SET_INLINE_THEME,
        payload: theme,
    };
}

export function setLogoUrl(url: string) {
    return {
        type: actionTypes.SET_LOGO_URL,
        payload: url,
    };
}

export function hidePayPal(status: boolean) {
    return {
        type: actionTypes.HIDE_PAYPAL,
        payload: status,
    };
}
