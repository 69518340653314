export const SET_GENDER = 'SET_GENDER';
export const SET_GOAL = 'SET_GOAL';
export const SET_LAST_PERFECT_WEIGHT = 'SET_LAST_PERFECT_WEIGHT';
export const SET_CURRENT_BODY = 'SET_CURRENT_BODY';
export const SET_FEEL_BREAK = 'SET_FEEL_BREAK';
export const SET_DIET = 'SET_DIET';
export const SET_PSYCHO_POWER = 'SET_PSYCHO_POWER';
export const SET_PSYCHO_BUSY = 'SET_PSYCHO_BUSY';
export const SET_PREFERENCE_EAT = 'SET_PREFERENCE_EAT';
export const SET_SLEEP_DURATION = 'SET_SLEEP_DURATION';
export const SET_TARGET_BODY = 'SET_TARGET_BODY';
export const SET_TARGET_ZONE = 'SET_TARGET_ZONE';
export const SET_WATER_CONSUMPTION = 'SET_WATER_CONSUMPTION';
export const SET_TARGET_WEIGHT = 'SET_TARGET_WEIGHT';
export const SET_CURRENT_WEIGHT = 'SET_CURRENT_WEIGHT';
export const SET_CONFIG = 'SET_CONFIG';
export const SET_AGE = 'SET_AGE';
export const SET_TALL = 'SET_TALL';
export const SET_TRAINING_LEVEL = 'SET_TRAINING_LEVEL';
export const SET_MEASURE_SYSTEM = 'SET_MEASURE_SYSTEM';
export const SET_NEXT_STEP = 'SET_NEXT_STEP';
export const SET_ONBOARDING_SCREENS_LIST = 'SET_ONBOARDING_SCREENS_LIST';
export const SET_WITH_UPSALE = 'SET_WITH_UPSALE';
export const INIT_AUTO_LOGIN = 'INIT_AUTO_LOGIN';
export const SET_URL_PARAMS = 'SET_URL_PARAMS';
export const SET_NAME = 'SET_NAME';
export const SET_OCCASION = 'SET_OCCASION';
export const SET_OCCASION_DATE = 'SET_OCCASION_DATE';
export const SET_AGE_RANGE = 'SET_AGE_RANGE';
export const SET_USERS_AMOUNT_DATA = 'SET_USERS_AMOUNT_DATA';
export const SKIP_ONBOARDING = 'SKIP_ONBOARDING';
export const SET_GOAL_ID = 'SET_GOAL_ID';
export const SET_DYNAMIC_CONFIGS = 'SET_DYNAMIC_CONFIGS';
export const SET_EXTRA_WORKOUT_TYPE = 'SET_EXTRA_WORKOUT_TYPE';
export const SET_STEP_TRACKER = 'SET_STEP_TRACKER';
export const SET_TRAINING_DAYS = 'SET_TRAINING_DAYS';
export const SET_BODY_PARTS = 'SET_BODY_PARTS';
export const SET_EQUIPMENT = 'SET_EQUIPMENT';
export const SET_USER_WORKOUT_TYPE = 'SET_USER_WORKOUT_TYPE';
export const SET_ACTIVITY_LEVEL = 'SET_ACTIVITY_LEVEL';

export const GET_EXTRA_WORKOUT_TYPE = 'ONBOARDING/GET_EXTRA_WORKOUT_TYPE';
export const GET_PLAN_DYNAMIC_CONFIGS = 'ONBOARDING/GET_PLAN_DYNAMIC_CONFIGS';
