/* eslint-disable max-lines */
export const DEFAULT_FLOW_PROJECT_NAME = 'mycoach';
export const DEFAULT_EXPIRE_CONFIG_TIME = 1440;

export const DEFAULT_FLOW = {
    branch_name: 'default_client',
    flow_name: 'Default_Client_coach',
    start_page: { id: 'index', name: 'index', config: {} },
    onboarding_flow: [
        { id: 'ob_social_proof', name: 'ob_social_proof', config: {} },
        { id: 'dem_motive_goal', name: 'dem_motive_goal', config: {} },
        { id: 'active_flev_act_body', name: 'active_flev_act_body', config: {} },
        { id: 'active_flev_targ_body', name: 'active_flev_targ_body', config: {} },
        { id: 'active_flev_targ_z', name: 'active_flev_targ_z', config: {} },
        { id: 'behave_hist_perfectW8', name: 'behave_hist_perfectW8', config: {} },
        { id: 'ob_fitness_level', name: 'ob_fitness_level', config: {} },
        { id: 'ob_stairs_over', name: 'ob_stairs_over', config: {} },
        { id: 'ob_fitness_walking', name: 'ob_fitness_walking', config: {} },
        { id: 'ob_pushups_squats', name: 'ob_pushups_squats', config: {} },
        { id: 'ob_height_a', name: 'ob_height_a', config: {} },
        { id: 'ob_weight_current_a', name: 'ob_weight_current_a', config: {} },
        { id: 'ob_weight_target_a', name: 'ob_weight_target_a', config: {} },
        { id: 'ob_age_a', name: 'ob_age_a', config: {} },
        { id: 'ob_feedback_age', name: 'ob_feedback_age', config: {} },
        { id: 'nutr_habbit_feel', name: 'nutr_habbit_feel', config: {} },
        { id: 'active_habit_slip', name: 'active_habit_slip', config: {} },
        { id: 'nutr_habbit_water', name: 'nutr_habbit_water', config: {} },
        { id: 'diets', name: 'diets', config: {} },
        { id: 'ob_occasion', name: 'ob_occasion', config: {} },
        { id: 'ob_occasion_result', name: 'ob_occasion_result', config: {} },
        { id: 'behave_eating_sc', name: 'behave_eating_sc', config: {} },
        { id: 'behave_multitasking_sc', name: 'behave_multitasking_sc', config: {} },
        { id: 'behave_routines_sc', name: 'behave_routines_sc', config: {} },
        { id: 'behave_oldhabits_sc', name: 'behave_oldhabits_sc', config: {} },
        { id: 'behave_impulse_sc', name: 'behave_impulse_sc', config: {} },
        { id: 'feedback_summary_fitlevel', name: 'feedback_summary_fitlevel', config: {} },
        { id: 'creating_b', name: 'creating_b', config: {} },
        { id: 'email_a', name: 'email_a', config: {} },
        { id: 'email_consent', name: 'email_consent', config: {} },
        { id: 'ob_name', name: 'ob_name', config: {} },
        { id: 'result_a', name: 'result_a', config: {} },
    ],
    payment_flow: [
        {
            id: 'payment_Da_checkout_Bravo11_b',
            name: 'payment_Da_checkout_Bravo11_b',
            parent_id: 'payment_Da_checkout_Bravo11_a',
            is_paid: false,
            skip_checkout: false,
            products: [
                {
                    payment_type: 'subscription',
                    id: 'ac79d9dd-2736-4db5-88ba-512c2dc1ea01',
                    price: 550,
                    name: 'One-week intro (5.50) + One-week (10.49)',
                    trial: 7,
                    period: 7,
                    price_per_state: 79,
                    start_price_per_state: 150,
                    is_preselected: false,
                    start_price: 1049,
                    product_state: 'per_day',
                    product_code: 'app_full_access',
                    ltv: 2083,
                },
                {
                    payment_type: 'subscription',
                    id: '3af9e081-b45d-401e-b858-c6a68e153467',
                    price: 1199,
                    name: 'One-month intro (11.99) + One-month (30.99)',
                    trial: 31,
                    period: 31,
                    price_per_state: 39,
                    start_price_per_state: 100,
                    is_preselected: true,
                    start_price: 3099,
                    product_state: 'per_day',
                    product_code: 'app_full_access',
                    ltv: 3022,
                },
                {
                    payment_type: 'subscription',
                    id: 'eb667562-a647-4ee7-bd72-b591148dd0d9',
                    price: 2149,
                    name: 'Three-month intro (21.49) + Three-month (53.19)',
                    trial: 90,
                    period: 90,
                    price_per_state: 24,
                    start_price_per_state: 59,
                    is_preselected: false,
                    start_price: 5319,
                    product_state: 'per_day',
                    product_code: 'app_full_access',
                    ltv: 3007,
                },
                {
                    payment_type: 'subscription',
                    id: '9ee130e4-8933-46da-87fa-2c3948a96fc5',
                    price: 1049,
                    name: 'One-week intro (10.49) + One-week (10.49)',
                    trial: 7,
                    period: 7,
                    price_per_state: 150,
                    start_price_per_state: 150,
                    is_preselected: false,
                    start_price: 1049,
                    product_state: 'per_day',
                    product_code: 'app_full_access',
                    ltv: 2434,
                },
                {
                    payment_type: 'subscription',
                    id: 'ee4ac51e-17c6-4e61-a565-8c5a39f15bdd',
                    price: 3099,
                    name: 'One-month intro (30.99) + One-month (30.99)',
                    trial: 31,
                    period: 31,
                    price_per_state: 100,
                    start_price_per_state: 100,
                    is_preselected: false,
                    start_price: 3099,
                    product_state: 'per_day',
                    product_code: 'app_full_access',
                    ltv: 5229,
                },
                {
                    payment_type: 'subscription',
                    id: 'f76ef55c-8026-422a-bbc9-c2b5d0442670',
                    price: 5319,
                    name: 'Three-month intro (53.19) + Three-month (53.19)',
                    trial: 90,
                    period: 90,
                    price_per_state: 59,
                    start_price_per_state: 59,
                    is_preselected: false,
                    start_price: 5319,
                    product_state: 'per_day',
                    product_code: 'app_full_access',
                    ltv: 5236,
                },
            ],
            config: {},
        },
        {
            id: 'upsale_2_a',
            name: 'upsale_2_a',
            parent_id: 'payment_Da_checkout_Bravo11_a',
            is_paid: true,
            skip_checkout: true,
            products: [
                {
                    payment_type: 'lifetime',
                    id: 'fat_burning_gd__weight_loss_gd',
                    price: 699,
                    name: 'fat_burning_gd__weight_loss_gd',
                    trial: 0,
                    period: 1,
                    price_per_state: 699,
                    start_price_per_state: 1,
                    is_preselected: true,
                    start_price: 1500,
                    product_state: 'per_week',
                    product_code: 'guide_all',
                    ltv: 491,
                },
                {
                    payment_type: 'lifetime',
                    id: 'weight_loss_gd',
                    price: 499,
                    name: 'weight_loss_gd',
                    trial: 0,
                    period: 1,
                    price_per_state: 499,
                    start_price_per_state: 1,
                    is_preselected: false,
                    start_price: 1000,
                    product_state: 'per_week',
                    product_code: 'guide_nutrition',
                    ltv: 351,
                },
                {
                    payment_type: 'lifetime',
                    id: 'fat_burning_gd',
                    price: 499,
                    name: 'fat_burning_gd',
                    trial: 0,
                    period: 1,
                    price_per_state: 499,
                    start_price_per_state: 1,
                    is_preselected: false,
                    start_price: 1000,
                    product_state: 'per_week',
                    product_code: 'guide_cardio',
                    ltv: 351,
                },
            ],
            config: {},
        },
        {
            id: 'upsale_2_a',
            name: 'upsale_2_a',
            parent_id: 'payment_Da_checkout_Bravo11_b',
            is_paid: true,
            skip_checkout: true,
            products: [
                {
                    payment_type: 'lifetime',
                    id: 'fat_burning_gd__weight_loss_gd',
                    price: 699,
                    name: 'fat_burning_gd__weight_loss_gd',
                    trial: 0,
                    period: 1,
                    price_per_state: 699,
                    start_price_per_state: 1,
                    is_preselected: true,
                    start_price: 1500,
                    product_state: 'per_week',
                    product_code: 'guide_all',
                    ltv: 491,
                },
                {
                    payment_type: 'lifetime',
                    id: 'weight_loss_gd',
                    price: 499,
                    name: 'weight_loss_gd',
                    trial: 0,
                    period: 1,
                    price_per_state: 499,
                    start_price_per_state: 1,
                    is_preselected: false,
                    start_price: 1000,
                    product_state: 'per_week',
                    product_code: 'guide_nutrition',
                    ltv: 351,
                },
                {
                    payment_type: 'lifetime',
                    id: 'fat_burning_gd',
                    price: 499,
                    name: 'fat_burning_gd',
                    trial: 0,
                    period: 1,
                    price_per_state: 499,
                    start_price_per_state: 1,
                    is_preselected: false,
                    start_price: 1000,
                    product_state: 'per_week',
                    product_code: 'guide_cardio',
                    ltv: 351,
                },
            ],
            config: {},
        },
        {
            id: 'payment_Da_checkout_Bravo11_a',
            name: 'payment_Da_checkout_Bravo11_a',
            parent_id: null,
            is_paid: null,
            skip_checkout: false,
            products: [
                {
                    payment_type: 'subscription',
                    id: '0eeb3104-bcce-4029-8dc0-6a48ded805ad',
                    price: 693,
                    name: 'One-week intro (6.93) + One-week (10.49)',
                    trial: 7,
                    period: 7,
                    price_per_state: 99,
                    start_price_per_state: 150,
                    is_preselected: false,
                    start_price: 1049,
                    product_state: 'per_day',
                    product_code: 'app_full_access',
                    ltv: 2405,
                },
                {
                    payment_type: 'subscription',
                    id: '9ea6bce5-34fe-4ee3-93d9-4ea5f455bc37',
                    price: 1519,
                    name: 'One-month intro (15.19) + One-month (30.99)',
                    trial: 31,
                    period: 31,
                    price_per_state: 49,
                    start_price_per_state: 100,
                    is_preselected: true,
                    start_price: 3099,
                    product_state: 'per_day',
                    product_code: 'app_full_access',
                    ltv: 4009,
                },
                {
                    payment_type: 'subscription',
                    id: '7ad6132e-71fc-47c9-9e82-dcea25af4ac3',
                    price: 2599,
                    name: 'Three-month intro (25.99) + Three-month (53.19)',
                    trial: 90,
                    period: 90,
                    price_per_state: 29,
                    start_price_per_state: 59,
                    is_preselected: false,
                    start_price: 5319,
                    product_state: 'per_day',
                    product_code: 'app_full_access',
                    ltv: 3922,
                },
                {
                    payment_type: 'subscription',
                    id: '9ee130e4-8933-46da-87fa-2c3948a96fc5',
                    price: 1049,
                    name: 'One-week intro (10.49) + One-week (10.49)',
                    trial: 7,
                    period: 7,
                    price_per_state: 150,
                    start_price_per_state: 150,
                    is_preselected: false,
                    start_price: 1049,
                    product_state: 'per_day',
                    product_code: 'app_full_access',
                    ltv: 2434,
                },
                {
                    payment_type: 'subscription',
                    id: 'ee4ac51e-17c6-4e61-a565-8c5a39f15bdd',
                    price: 3099,
                    name: 'One-month intro (30.99) + One-month (30.99)',
                    trial: 31,
                    period: 31,
                    price_per_state: 100,
                    start_price_per_state: 100,
                    is_preselected: false,
                    start_price: 3099,
                    product_state: 'per_day',
                    product_code: 'app_full_access',
                    ltv: 5229,
                },
                {
                    payment_type: 'subscription',
                    id: 'f76ef55c-8026-422a-bbc9-c2b5d0442670',
                    price: 5319,
                    name: 'Three-month intro (53.19) + Three-month (53.19)',
                    trial: 90,
                    period: 90,
                    price_per_state: 59,
                    start_price_per_state: 59,
                    is_preselected: false,
                    start_price: 5319,
                    product_state: 'per_day',
                    product_code: 'app_full_access',
                    ltv: 5236,
                },
            ],
            config: {},
        },
        {
            id: 'vip_support',
            is_paid: true,
            name: 'vip_support',
            parent_id: 'upsale_2_a',
            skip_checkout: false,
            products: [
                {
                    id: '4071853f-eaf9-4988-bbb8-6bd9ab872e53',
                    is_preselected: false,
                    ltv: 167,
                    name: 'One-month (0.99)',
                    payment_type: 'subscription',
                    period: 30,
                    price: 99,
                    price_per_state: 99,
                    product_state: 'per_month',
                    start_price: 99,
                    start_price_per_state: 99,
                    product_code: 'vip_support',
                    trial: 0,
                },
            ],
        },
        {
            id: 'vip_support',
            is_paid: false,
            name: 'vip_support',
            parent_id: 'upsale_2_a',
            skip_checkout: false,
            products: [
                {
                    id: '4071853f-eaf9-4988-bbb8-6bd9ab872e53',
                    is_preselected: false,
                    ltv: 167,
                    name: 'One-month (0.99)',
                    payment_type: 'subscription',
                    period: 30,
                    price: 99,
                    price_per_state: 99,
                    product_state: 'per_month',
                    product_code: 'vip_support',
                    start_price: 99,
                    start_price_per_state: 99,
                    trial: 0,
                },
            ],
        },
    ],
    expire: 1633705410444,
};
