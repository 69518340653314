import { OPTION_TITLE_PATTERN } from 'constants/onboardingConfig';

import { isEmptyValue } from 'helpers/root/utils';

import {
    ResponseExtraWorkoutTypesDataItem,
    ResponseExtraWorkoutTypesDataItemExtraWorkoutsItem
} from 'types/onboarding/extraWorkoutType';

export function parseOptionKeys(keyOptions = '') {
    let options: string[] = [];

    try {
        options = JSON.parse(keyOptions);
    } catch (e) {
        console.error('Invalid Key Options format', e);
    }

    return options;
}

export const checkOptionsCount = ({ config = {} }: { config?: Record<string, string | undefined> }) => {
    const MIN_OPTIONS_COUNT = 2;

    const optionKeys = Object.keys(config).filter((configKey) => configKey.startsWith(OPTION_TITLE_PATTERN));
    const options = optionKeys.map((optionKey) => config[optionKey]).filter(Boolean);

    return options.length >= MIN_OPTIONS_COUNT;
};

export const checkKeysCount = ({ config = {} }: { config?: Record<string, string | undefined> }) => {
    const MIN_KEYS_COUNT = 2;
    const keys = parseOptionKeys(config.keyOptions);

    return keys.length >= MIN_KEYS_COUNT;
};

export const getExtraWorkoutTypesByName = ({ userWorkoutTypesList, workoutTypesList }: { userWorkoutTypesList: string[], workoutTypesList: ResponseExtraWorkoutTypesDataItem[] }) => {
    const extraWorkoutTypesList: { workout_type_id: number; }[] = [];

    workoutTypesList && workoutTypesList[0]?.extra_workouts?.map((item: ResponseExtraWorkoutTypesDataItemExtraWorkoutsItem) => {
        if (item.internal_name && userWorkoutTypesList.includes(item.internal_name)) {
            extraWorkoutTypesList.push({
                workout_type_id: item.id as number,
            });
        }
    });

    return !isEmptyValue(extraWorkoutTypesList) ? extraWorkoutTypesList : null;
};
