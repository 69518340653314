import { createSelector } from 'reselect';

import { RootState } from '../rootReducer';

const selectOnboarding = (state: RootState) => state.onboarding;

const selectGender = createSelector(selectOnboarding, (onboarding) => onboarding.gender);
const selectAge = createSelector(selectOnboarding, (onboarding) => onboarding.age);
const selectAgeRange = createSelector(selectOnboarding, (onboarding) => onboarding.age_range);
const selectConfig = createSelector(selectOnboarding, (onboarding) => onboarding.config);
const selectCurrentBody = createSelector(selectOnboarding, (onboarding) => onboarding.current_body);
const selectCurrentWeight = createSelector(selectOnboarding, (onboarding) => onboarding.current_weight);
const selectTrainingLevel = createSelector(selectOnboarding, (onboarding) => onboarding.training_level);
const selectGoal = createSelector(selectOnboarding, (onboarding) => onboarding.goal);
const selectHowFast = createSelector(selectOnboarding, (onboarding) => onboarding.how_fast);
const selectMeasureSystem = createSelector(selectOnboarding, (onboarding) => onboarding.measureSystem);
const selectNextStep = createSelector(selectOnboarding, (onboarding) => onboarding.nextStep);
const selectOnboardingScreensList = createSelector(selectOnboarding, (onboarding) => onboarding.onboardingScreensList);
const selectPsychBusy = createSelector(selectOnboarding, (onboarding) => onboarding.psychBusy);
const selectPsychLazy = createSelector(selectOnboarding, (onboarding) => onboarding.psychLazy);
const selectTall = createSelector(selectOnboarding, (onboarding) => onboarding.tall);
const selectTargetWeight = createSelector(selectOnboarding, (onboarding) => onboarding.target_weight);
const selectTargetZone = createSelector(selectOnboarding, (onboarding) => onboarding.target_zone);
const selectUrlParams = createSelector(selectOnboarding, (onboarding) => onboarding.urlParams);
const selectWantBody = createSelector(selectOnboarding, (onboarding) => onboarding.want_body);
const selectName = createSelector(selectOnboarding, (onboarding) => onboarding.name);
const selectDiet = createSelector(selectOnboarding, (onboarding) => onboarding.diet);
const selectOccasion = createSelector(selectOnboarding, (onboarding) => onboarding.occasion);
const selectOccasionDate = createSelector(selectOnboarding, (onboarding) => onboarding.occasionDate);
const selectUsersAmount = createSelector(selectOnboarding, (onboarding) => onboarding.usersAmountData);
const selectGoalId = createSelector(selectOnboarding, (onboarding) => onboarding.goalId);
const selectDynamicConfig = createSelector(selectOnboarding, (onboarding) => onboarding.dynamicConfig);
const selectExtraWorkoutType = createSelector(selectOnboarding, (onboarding) => onboarding.extraWorkoutType);
const selectStepTracker = createSelector(selectOnboarding, (onboarding) => onboarding.stepTracker);
const selectTrainingDays = createSelector(selectOnboarding, (onboarding) => onboarding.trainingDays);
const selectBodyParts = createSelector(selectOnboarding, (onboarding) => onboarding.bodyParts);
const selectEquipment = createSelector(selectOnboarding, (onboarding) => onboarding.equipment);
const selectUserWorkoutTypes = createSelector(selectOnboarding, (onboarding) => onboarding.userWorkoutTypes);
const selectActivityLevel = createSelector(selectOnboarding, (onboarding) => onboarding.activity_level);

export {
    selectOnboarding,
    selectGender,
    selectCurrentWeight,
    selectAge,
    selectConfig,
    selectCurrentBody,
    selectTrainingLevel,
    selectGoal,
    selectHowFast,
    selectMeasureSystem,
    selectNextStep,
    selectOnboardingScreensList,
    selectPsychBusy,
    selectPsychLazy,
    selectTall,
    selectTargetWeight,
    selectTargetZone,
    selectUrlParams,
    selectWantBody,
    selectName,
    selectDiet,
    selectOccasion,
    selectOccasionDate,
    selectAgeRange,
    selectUsersAmount,
    selectDynamicConfig,
    selectExtraWorkoutType,
    selectGoalId,
    selectStepTracker,
    selectTrainingDays,
    selectBodyParts,
    selectEquipment,
    selectUserWorkoutTypes,
    selectActivityLevel,
};
