import { ToastContainer } from 'react-toastify';
import React from 'react';

import 'react-toastify/dist/ReactToastify.css';

import { INotificationWrapperProps } from './types';

const NotificationWrapper = ({ children }: INotificationWrapperProps) => (
    <>
        <ToastContainer
            autoClose={3000}
            hideProgressBar
            closeButton={({ closeToast }) => (
                <span tabIndex={0} role="button" className="close-item" onClick={closeToast} />
            )}
        />
        {children}
    </>
);

export default NotificationWrapper;
