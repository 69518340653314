import { ERROR_LEVELS, SENTRY_APP } from 'sentry-utils';
import { connect } from 'react-redux';
import React, { Component } from 'react';

import { selectTestaniaName, selectCurrantBranchName } from 'redux/Testania/selectors';
import { RootState } from 'redux/rootReducer';

import sentry from 'services/Sentry/SentryInstance';

import { Props, State } from './interface';
import { ErrorFallback } from './ErrorFallback';

class ErrorBoundary extends Component<Props, State> {
    public state: State = {
        hasError: false,
        error: null,
    };

    public static getDerivedStateFromError(): State {
        return { hasError: true };
    }

    public logError(error: Error) {
        const { testaniaName, currentBranchName } = this.props;

        sentry.logError(error, SENTRY_APP, ERROR_LEVELS.CRITICAL, { testaniaName, currentBranchName });
    }

    public componentDidCatch(error: Error) {
        this.setState({ error });

        this.logError(error);
    }

    public render() {
        if (this.state.hasError && this.state.error) {
            return <ErrorFallback error={this.state.error} />;
        }

        return this.props.children;
    }
}
const mapStateToProps = (state: RootState) => {
    return {
        testaniaName: selectTestaniaName(state),
        currentBranchName: selectCurrantBranchName(state),
    };
};

export default connect(mapStateToProps, null)(ErrorBoundary);
