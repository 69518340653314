import { uaParser } from 'wikr-core-analytics';

import { DEVICE_OS } from 'constants/payments';

import { getLanguage } from 'helpers/utils';

import config from '../config';

const UAParser = new uaParser();

export const isFirstMirror = () => {
    return window.location.host === config.FIRST_MIRROR_DOMAIN_NAME || '';
};

export const getRelease = () => {
    return JSON.parse(config.RELEASE);
};

export const getProjectEmail = () => {
    const domainName = window.location.host;

    return isFirstMirror() ? 'support@bodyfitness.io' : `support@${domainName}`;
};

export const generatePolicyLink = (mirror: string, link: string) => {
    const language = getLanguage();

    return isFirstMirror() ? mirror : `${link}?language=${language}`; // TODO: use URL() constructor after mirror removing
};

export const getDeviceOS = () => {
    const userAgent = window.navigator.userAgent;
    const getOSData = UAParser.setUA(userAgent).getOS();

    return {
        name: getOSData?.name?.toUpperCase() || DEVICE_OS.DEFAULT,
        version: getOSData?.version,
    };
};

// TODO must be combined with the method getDeviceOS
export const getBrowserOS = () => {
    const userAgent = window.navigator.userAgent;

    return UAParser.setUA(userAgent)?.getBrowser()?.name?.toUpperCase();
};

export const getDevicePlatform = () => {
    const { name: deviceOSName } = getDeviceOS();

    switch (deviceOSName) {
        case DEVICE_OS.ANDROID:
            return DEVICE_OS.ANDROID;
        case DEVICE_OS.IOS:
        case DEVICE_OS.MAC_OS:
            return DEVICE_OS.IOS;

        default:
            return DEVICE_OS.ANDROID;
    }
};

export const getCurrentLocalization = () => {
    return window.location.pathname.split('/')[1];
};
