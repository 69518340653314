export const DEFAULT_DYNAMIC_TITLE = 'GET YOUR CUSTOMIZED WEIGHT LOSS PLAN';

export enum NOTIFY_STATUS {
    SUCCESS = 'SUCCESS',
    ERROR = 'ERROR',
}

export const HOTJAR_VERSION = 6;

export const DEFAULT_ERROR_MESSAGE = 'Untracked error';

export const EMAIL_EXISTS_ERROR = 'User with this email already exists';

export const GOAL_MUSCLE_GAIN = 'muscle_gain';

export const BREAK_POINTS = {
    tablet: 768,
    desktop: 1440,
};

export const GOOGLE_PLAY_DOWNLOAD_COUNT = 35;

export const AMOUNT_FIVE_STARS = '126.500+';

export const DEFAULT_TIME_TIMER = 10;

export const BANK_CARD_CUSTOM_BUTTON_ID = 'customSubmitButton';

export const USER_REQUIRED_FIELDS = ['email', 'gender'];
