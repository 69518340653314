import { Main, Provider } from 'wikr-core-analytics';

import * as actionTypes from 'redux/User/actionTypes';

import {
    UserInfoResponse,
    UserSignUpError,
    IUpdateSendpulseMailing,
    ChangeUserData,
} from 'types/user/userApiInterface';
import { UserName } from 'types/store/userStore';
import { Dispatch } from 'types/commonInterfaces';

export function setUserData(payload: UserInfoResponse) {
    return async (dispatch: Dispatch) => {
        const { user_id, is_paid } = payload;

        dispatch(setUserId(user_id));
        dispatch(setIsPaid(is_paid));
        dispatch(setUpdatedAtTime(new Date()));
    };
}

export function setIsPaid(status: boolean) {
    return {
        type: actionTypes.SET_IS_PAID,
        payload: status,
    };
}

export function setUpdatedAtTime(time: Date) {
    return {
        type: actionTypes.SET_UPDATED_AT_TIME,
        payload: time,
    };
}

export function signUp(data: object) {
    return {
        type: actionTypes.SIGN_UP,
        payload: data,
    };
}

export function signUpSuccess(data: object) {
    return {
        type: actionTypes.SIGN_UP_SUCCESS,
        payload: data,
    };
}

export function signUpFail(error: string) {
    return {
        type: actionTypes.SIGN_UP_FAIL,
        payload: error,
    };
}

export function setSignUpErrorData(data: UserSignUpError) {
    return {
        type: actionTypes.SET_SIGN_UP_ERROR_DATA,
        payload: data,
    };
}

export function setIsInitTestania(status: boolean) {
    return {
        type: actionTypes.SET_IS_INIT_TESTANIA,
        payload: status,
    };
}

export function setLoader(payload: boolean) {
    return {
        type: actionTypes.SET_LOADER,
        payload,
    };
}

export function updateSendpulseMailing(payload: IUpdateSendpulseMailing) {
    return {
        type: actionTypes.UPDATE_SENDPULS_MAILING,
        payload,
    };
}

export function updateUserInfo(data: ChangeUserData) {
    return {
        type: actionTypes.UPDATE_INFO,
        payload: data,
    };
}

export function setUserId(id: string | number | null) {
    if (id) Provider.setUserId(Number(id));

    return {
        type: actionTypes.SET_USER_ID,
        payload: id,
    };
}

export function setUserEmail(email: string | null) {
    return {
        type: actionTypes.SET_EMAIL,
        payload: email,
    };
}

export function setUserName(data: UserName) {
    return {
        type: actionTypes.SET_USER_NAME,
        payload: data,
    };
}

export function setToken(token: string | null) {
    return {
        type: actionTypes.SET_USER_TOKEN,
        payload: token,
    };
}

export function setCountry(country: string | null) {
    return {
        type: actionTypes.SET_COUNTRY,
        payload: country,
    };
}

export function setIsAutologinComplete(status: boolean) {
    return {
        type: actionTypes.SET_IS_AUTOLOGIN_COMPLETE,
        payload: status,
    };
}

export function setDefaultAmazonAnalyticData() {
    return async (dispatch: Dispatch) => {
        try {
            const data = (await Main?.getDefaultAmazonAnalyticPurchaseData?.()) || {};

            dispatch({
                type: actionTypes.SET_DEFAULT_AMAZON_ANALYTIC_DATA,
                payload: data,
            });
        } catch (error) {
            return { error };
        }
    };
}

export function moveUserToPage(status: boolean) {
    return {
        type: actionTypes.SHOULD_USER_MOVE_TO_PAGE,
        payload: status,
    };
}

export function setUserPlatform(platform: string) {
    return {
        type: actionTypes.SET_PLATFORM,
        payload: platform,
    };
}
