import React from 'react';
import classNames from 'classnames/bind';

import styles from './Loader.module.css';

import { ILoaderProps } from './types';

const cx = classNames.bind(styles);

const Loader = ({ overlayLoader, additionalClass = '' }: ILoaderProps) => {
    const loaderStyles = cx({ loader: true, overlayLoader, [additionalClass]: additionalClass });

    return <div className={loaderStyles} />;
};

export default Loader;
