import { Trans } from 'react-i18next';
import React from 'react';

import Title from 'components/Title';
import Container from 'components/Container';

export function ErrorFallback({ error }: { error: Error }) {
    return (
        <Container staticHeader>
            <Title h4New globalClassNames={['mb-8', 'centered']}>
                <Trans>Something went wrong</Trans>
            </Title>
            <pre>{error?.message}</pre>
        </Container>
    );
}
