import { isMaleGender, getToFixedNumber } from 'helpers/utils';

import { MeasuringSystemType } from 'types/onboarding/onboarding';
import { NumberSystemType } from './types';

import { DEFAULT_MEASUREMENT as CONSTANTS } from './config';

class Convert {
    numberSystem: MeasuringSystemType;
    needConvertResult: boolean;

    constructor(numberSystem?: MeasuringSystemType) {
        this.numberSystem = numberSystem || 'METRIC';
        this.needConvertResult = numberSystem === 'IMPERIAL';
    }

    fromLbsToKg(lbs: number) {
        return lbs / 2.2046;
    }

    fromKgToLbs(kg: number | string) {
        return Math.round(+kg * 2.2046);
    }

    fromInToCm(inch: number) {
        return inch / 0.3937;
    }

    fromCmToIn(cm: number) {
        return cm * 0.3937;
    }

    fromLittersToGallons(litters: number) {
        return litters / 3.785;
    }

    fromGallonsToLitters(gallons: number) {
        return gallons * 3.785;
    }

    fromCmToFeetAndInches(cm: number) {
        const value: { feet?: number; inches?: number } = {};

        const length = cm / 2.54;
        const feet = Math.trunc(length / 12);
        const inches = length - 12 * feet;

        value.feet = feet;
        value.inches = inches;

        return value;
    }

    getUnitByNumberSystem(type: NumberSystemType) {
        const UNITS = {
            IMPERIAL: {
                weight: 'lbs',
                height: 'in',
                volume: 'GAL',
            },
            METRIC: {
                weight: 'kg',
                height: 'cm',
                volume: 'L',
            },
        };

        return UNITS[this.numberSystem][type];
    }

    getBMI(weight: number | string | null, height: number | string) {
        return getToFixedNumber(Number(weight) / Math.pow(Number(height) / 100, 2));
    }

    getLoseWeightByBMI(ВМI: number) {
        const checkBMI1 = ВМI < 34.99 ? 2 : 2.5;
        const checkBMI2 = ВМI < 29.99 ? 1.5 : checkBMI1;

        const result = ВМI < 24.99 ? 1 : checkBMI2;

        return this.needConvertResult ? this.fromKgToLbs(result) : result;
    }

    getLabelByBMI(ВМI: number) {
        const getLabelByBMI1 = ВМI < 29.99 ? 'Overweight' : 'Obesity';
        const getLabelByBMI2 = ВМI < 24.99 ? 'Normal' : getLabelByBMI1;

        return ВМI < 18.49 ? 'Underweight' : getLabelByBMI2;
    }

    dailyTargetBMR(
        gender: string,
        currentWeight = CONSTANTS.defaultCurrentWeight,
        height = CONSTANTS.defaultHeight,
        age = CONSTANTS.defaultAge
    ) {
        const coef = isMaleGender(gender) ? +5 : -161;

        return 10 * +currentWeight + 6.25 * +height - 5 * +age + coef;
    }

    dailyTDE(bmr: number, activity = CONSTANTS.defaultFitnessLevel) {
        return bmr * CONSTANTS.fitnessLvlToActivityLvl[activity || CONSTANTS.defaultFitnessLevel];
    }

    dailyCalorieDCR(TDE: number, targetWeight: number, currentWeight = CONSTANTS.defaultCurrentWeight) {
        if (targetWeight < currentWeight) {
            return TDE * 0.2;
        } else {
            return TDE * 0.25;
        }
    }

    kgPerWeek(dailyCalorie: number) {
        return (dailyCalorie * 7) / 7700;
    }

    daysToTargetWeight(currentWeight = CONSTANTS.defaultCurrentWeight, targetWeight: number, kgPerWeek: number) {
        if (targetWeight < currentWeight) {
            return ((currentWeight - targetWeight) / kgPerWeek) * 7;
        } else {
            return ((targetWeight - currentWeight) / kgPerWeek) * 7;
        }
    }

    calorieRangeMin(tde: number) {
        return 0.95 * tde;
    }

    getCurrentWeight(weight: number) {
        const fixedWeight = Math.round(Number(weight));

        return this.needConvertResult ? this.fromKgToLbs(weight) : fixedWeight;
    }

    roundNumber(val: number, bitness = 10) {
        return Math.round(val / bitness) * bitness;
    }

    getCalorieRangeMax(tde: number) {
        return 1.05 * tde;
    }

    getWaterPerDay(currentWeight = CONSTANTS.defaultCurrentWeight, activityLevel = CONSTANTS.defaultFitnessLevel) {
        // 41.6745 = 2.205*2/3*28.35;
        const coefByActivityLevel =
            CONSTANTS.activityLevelLitters[
                CONSTANTS.fitnessLvlToActivityLvl[activityLevel || CONSTANTS.defaultFitnessLevel]
            ];
        const litters = (currentWeight * 41.6745) / 1000 + coefByActivityLevel;

        return getToFixedNumber(
            this.needConvertResult ? this.fromLittersToGallons(litters) : getToFixedNumber(litters)
        );
    }

    fromFeetAndInchesToCentimeters(ft: number, inch: number) {
        const feetInCM = Number(ft) * 30.48;
        const inchesInCM = Number(inch) * 2.54;

        return getToFixedNumber(feetInCM + inchesInCM);
    }
}

export default Convert;
