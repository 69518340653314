import { Theme } from 'wikr-core-components';
import { useDispatch } from 'react-redux';
import { useState } from 'react';

import { setInlineTheme, setLogoUrl } from 'redux/UiEffects/actions';

import { DEFAULT_FAVICON_URL } from 'constants/links';

import { getFaviconElement } from 'helpers/root/utils';

type ThemeConfigScheme = {
    inlineStyle: object;
    themeStyle: object;
    faviconUrl: string;
    logoUrl: string;
};

const useThemeConfiguration = (themeConfig: Record<string, ThemeConfigScheme>): Theme => {
    const dispatch = useDispatch();

    const [theme, setTheme] = useState<Theme>();

    if (!theme) {
        const hostName = window.location.host;

        const { inlineStyle, themeStyle, logoUrl, faviconUrl } = themeConfig?.[hostName] || themeConfig['default'];

        const faviconElement = getFaviconElement() as HTMLLinkElement;

        faviconElement.href = faviconUrl || DEFAULT_FAVICON_URL;

        setTheme(themeStyle as Theme);
        dispatch(setLogoUrl(logoUrl));
        dispatch(setInlineTheme(inlineStyle));
    }

    return theme ?? (themeConfig['default'].themeStyle as Theme);
};

export default useThemeConfiguration;
