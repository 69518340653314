import { Main } from 'wikr-core-analytics';
import { BLACK_LIST_HANDLED_ERROR_NAMES, IGNORE_ERRORS_LIST, IGNORE_URLS_LIST } from 'sentry-utils';
import { Integrations } from '@sentry/tracing';
import * as Sentry from '@sentry/react';
import { Breadcrumb, BreadcrumbHint, Event, EventHint, SeverityLevel } from '@sentry/react';

import { checkFbBots } from 'helpers/utils';
import { checkIsOldDevices, getCustomTags } from './helpers';

import { ErrorType, Extras, IError, ISentry, Tags, UserInfo } from './types';

const BLACK_LIST_HANDLED_ERROR_NAMES_LOCAL = [
    ...BLACK_LIST_HANDLED_ERROR_NAMES,
    'undefined is not an object (evaluating \'this.socket.unsubscribe\')',
    'Cannot read properties of undefined (reading \'Domain\')',
];

const IGNORE_ERRORS_LIST_LOCAL = [
    ...IGNORE_ERRORS_LIST,
    /.*undefined is not an object (evaluating '__gCrWeb.learningToolsRuntimeBridge.raiseMessageFromHost').*/g,
    /.*WKWebView API client did not respond to this postMessage'.*/g,
    /.undefined is not an object (evaluating 'ceCurrentVideo.currentTime')./g,
    /.Unexpected token 'else'./g,
    /.undefined is not an object (evaluating 'window.vampBridge.getArticleHeight').'/g,
    /.null is not a function (near '...(null)...')./g,
    /.NetworkError when attempting to fetch resource./g,
    /.Non-Error promise rejection captured with value:./g,
    /.missing frame target./g,
    /.getPercent is not defined./g,
    /.Cannot contact the gateway at this time../g,
    /.null is not a function (near '...(null)...')/g,
    /.Can't find variable: msDiscoverChatAvailable./g,
];

export default class SentryClient {
    sentryDSN: string;
    env: 'stage' | 'prod' | string;
    release?: string;
    tracesSampleRate?: number;
    private isInited: boolean;
    private sentry: typeof Sentry;

    constructor({ sentryDSN, env, release, tracesSampleRate }: ISentry) {
        this.sentryDSN = sentryDSN;
        this.env = env || 'stage';
        this.release = release || '1.0.0';
        this.tracesSampleRate = tracesSampleRate || 1.0;
        this.isInited = false;
        this.sentry = Sentry;
    }

    init() {
        if (this.isInited) return;

        Sentry.init({
            dsn: this.sentryDSN,
            integrations: [new Integrations.BrowserTracing()],
            environment: this.env,
            debug: this.env !== 'prod',
            release: this.release,
            tracesSampleRate: this.tracesSampleRate,
            maxBreadcrumbs: 10,
            beforeBreadcrumb: (breadcrumb: Breadcrumb, hint: BreadcrumbHint) =>
                SentryClient.prepareBreadcrumb(breadcrumb, hint),
            beforeSend: (event: Event, hint: EventHint) => SentryClient.filterEventsBeforeSend(event, hint),
            ignoreErrors: IGNORE_ERRORS_LIST_LOCAL || [],
            denyUrls: IGNORE_URLS_LIST || [],
        });

        this.isInited = true;
    }

    public logError(error: IError, type: ErrorType, errorLevel: SeverityLevel, extras?: Extras, customTags?: Tags) {
        const customTagsArr = customTags || getCustomTags(error, type, extras);

        SentryClient.getAnalyticData().then((analyticData) =>
            this.sentry.withScope((scope: Sentry.Scope) => {
                extras && scope.setExtras({ ...extras, ...analyticData });

                scope.setTag('ERROR_TYPE', type);
                scope.setTag('ERROR_LEVEL', errorLevel);
                scope.setTag('aws_id', analyticData?.aws_id || null);
                scope.setLevel(errorLevel);

                customTagsArr.length && customTagsArr.forEach(([tag, value]) => scope.setTag(tag, value));

                this.sentry.captureMessage(SentryClient.prepareErrorByCode(error, type));
            })
        );
    }

    public setUser(userData: UserInfo) {
        this.sentry.setUser({ ...userData });
    }

    private static prepareErrorByCode(error: IError, type: ErrorType) {
        if (type === 'PAYMENTS') {
            const errorTypeMessage = 'details' in error && error?.details?.message;
            const failTypeMessage = 'message' in error && error?.message;

            const errorMessage = errorTypeMessage || failTypeMessage;
            const errorTitle = errorMessage ? errorMessage : error;

            const errorDetails = 'value' in error && error?.value?.name;

            return `[${type}] | : ${JSON.stringify(errorTitle)}${errorDetails ? ` | ${errorDetails}` : ''}`;
        }

        return `[${type}] | : ${JSON.stringify(error)} | ${error}`;
    }

    private static async getAnalyticData() {
        const isInitAmazonAnalytic = Main.checkIsAlreadyInited({ name: 'amazon' });

        return (isInitAmazonAnalytic && (await Main?.getDefaultAmazonAnalyticPurchaseData?.())) || {};
    }

    private static prepareBreadcrumb(breadcrumb: Sentry.Breadcrumb, hint: Sentry.BreadcrumbHint) {
        if (breadcrumb.level === 'error' || breadcrumb.type === 'http') {
            return { ...breadcrumb, ...hint };
        }

        return null;
    }

    private static filterEventsBeforeSend(event: Event, hint: EventHint) {
        const error = hint.originalException;

        if (
            (typeof error === 'string' && SentryClient.matchSubstring(error)) ||
            checkIsOldDevices(event) ||
            checkFbBots()
        ) {
            return null;
        }

        return event;
    }

    private static matchSubstring(error: string) {
        return BLACK_LIST_HANDLED_ERROR_NAMES_LOCAL.some((item) => error.includes(item));
    }
}
