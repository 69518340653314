import React from 'react';
import classNames from 'classnames/bind';

import styles from './ModalClose.module.css';

type Props = {
    onClick?: () => void;
    isLeftClose?: boolean;
    isFixed?: boolean;
    isSmallClose?: boolean;
    notificationModalIcon?: boolean;
};

function ModalClose({ notificationModalIcon, isLeftClose, onClick = () => {}, isFixed, isSmallClose }: Props) {
    const cx = classNames.bind(styles);

    const modalStyles = cx({
        closePopup: true,
        closePopupFixed: isFixed,
        notificationModal__close: notificationModalIcon,
        closePopup__left: isLeftClose,
        closePopup__small: isSmallClose,
    });

    return <span role="img" className={modalStyles} onClick={onClick} data-locator="closeCheckoutPopup" />;
}

export default ModalClose;
