import { put, putResolve, call, select, takeLatest, delay } from 'redux-saga/effects';

import { setUserEmail, signUp } from 'redux/User/actions';
import { selectTestaniaName } from 'redux/Testania/selectors';
import { SET_TESTANIA_LOGIN_STATUS, SET_COMPLETE_OBS } from 'redux/Testania/actionTypes';
import { SKIP_ONBOARDING } from 'redux/Onboadring/actionTypes';
import {
    setAge,
    setCurrentWeight,
    setGender,
    setTall,
    setTargetWeight,
    skipOnboarding,
} from 'redux/Onboadring/actions';

export function* skip({ payload }: ReturnType<typeof skipOnboarding>) {
    const generatedEmail = `fit_coach11+${Date.now()}@gmail.com`;

    yield put(setGender('female'));
    yield put(setUserEmail(generatedEmail));
    yield put(setTall({ value: 180 }));
    yield put(setAge(30));
    yield put(setTargetWeight({ value: 70 }));
    yield put(setCurrentWeight({ value: 80 }));

    yield putResolve(
        signUp({
            setErrors: () => console.error('error'),
            toNextPage: () => console.warn('next'),
        })
    );

    try {
        const completeObs: string = yield select(selectTestaniaName);

        yield put({
            type: SET_TESTANIA_LOGIN_STATUS,
            payload: true,
        });
        yield put({
            type: SET_COMPLETE_OBS,
            payload: completeObs,
        });

        yield delay(2500);

        yield call(payload.reload);
    } catch (error) {
        console.warn(error);
    }
}

export const skipOnBoardingSaga = [takeLatest(SKIP_ONBOARDING, skip)];
