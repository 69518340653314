import config from 'config';

import UserApi from './components/User';
import PaymentApi from './components/Payment';

import { IOnErrorData } from './types/api';

import ApiClient from './apiClient';

const TOKEN_KEY = 'token';

export default function () {
    const errorHandlers: Array<(data: any) => void> = [];

    const onError = (fn: (errorData: IOnErrorData) => void) => {
        errorHandlers.push(fn);
    };

    const handleError = (errorData: IOnErrorData) => {
        errorHandlers.forEach((errorHandler) => errorHandler(errorData));
    };

    const api = new ApiClient({ apiUrl: config.API_URL, onError: handleError });
    const user = new UserApi({ apiClient: api });
    const payment = new PaymentApi({ apiClient: api });

    window.addEventListener('storage', function (storageEvent) {
        if (TOKEN_KEY === storageEvent.key) {
            const { newValue: token } = storageEvent;
            if (token) {
                api.private.setAuthToken(token);
                user.apiClient.private.setAuthToken(token);
                payment.apiClient.private.setAuthToken(token);
            }
        }
    });

    return {
        user,
        payment,
        onError,
    };
}
