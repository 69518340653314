import React from 'react';
import classNames from 'classnames/bind';

import { isFirstMirror } from 'helpers/settings';

import stylesFirstMirror from './LogoFirstMirror.module.css';
import styles from './Logo.module.css';

import LogoWhite from 'assets/img/logo/logoWhite.webp';
import LogoFirstMirror from 'assets/img/logo/logoFirstMirror.webp';
import LogoDefault from 'assets/img/logo/logo.webp';

const images = {
    DEFAULT: LogoDefault,
    WHITE: LogoWhite,
    MIRROR: LogoFirstMirror,
};

import Text from 'components/Text';

const cx = classNames.bind(isFirstMirror() ? stylesFirstMirror : styles);

type Props = {
    centered?: boolean;
    white?: boolean;
    isOnboarding?: boolean;
    onboardingLogo?: boolean;
};

export default function Logo(props: Props) {
    const { centered, white, isOnboarding, onboardingLogo } = props;

    const logoClass = cx({
        logo: true,
        centered,
        white,
        isOnboarding,
    });
    const logoImgClass = cx({
        logoImgDefault: true,
        logoImgOnboarding: isOnboarding && onboardingLogo,
    });
    const logoTextClass = cx({
        logoText: true,
        biggerLogoText: !onboardingLogo,
    });

    let LogoImg = images.DEFAULT;

    if (isFirstMirror()) {
        LogoImg = images.MIRROR;
    } else if (white) {
        LogoImg = images.WHITE;
    }

    return (
        <div className={logoClass}>
            <img src={LogoImg} alt="logo" className={logoImgClass} />
            <Text bold letterSpacing1 white={white}>
                <span className={logoTextClass}>FitCoach</span>
            </Text>
        </div>
    );
}
