import { put, select, takeLatest } from 'redux-saga/effects';

import api from 'apiSingleton';

import { selectToken } from 'redux/User/selectors';
import { UPDATE_INFO_SUCCESS, UPDATE_INFO_FAIL } from 'redux/User/actionTypes';
import { UPDATE_INFO } from 'redux/User/actionTypes';
import { moveUserToPage, setCountry, setLoader, setUserEmail, setUserName } from 'redux/User/actions';
import { setAge, setCurrentWeight, setGender, setMeasure, setTall } from 'redux/Onboadring/actions';

import { EMAIL_EXISTS_ERROR } from 'constants/values';
import { GENERAL_ERROR_TOAST_MESSAGE, GENERAL_SUCCESS_TOAST_MESSAGE } from 'constants/toastConfigs';

import { getAgeFromBirthday } from 'helpers/utils';

import { ChangeUserData, UserInfoResponse } from 'types/user/userApiInterface';
import { MeasuringSystemType } from 'types/onboarding/onboarding';
import { IGender, ActionType } from 'types/commonInterfaces';

function* updateUserInfo({ payload }: ActionType<ChangeUserData>) {
    const { data, notify, setEmailError } = payload;

    yield put(setLoader(true));

    try {
        const userData: UserInfoResponse = yield api.user.update(data);
        const token: string = yield select(selectToken);

        const age = getAgeFromBirthday(userData?.age);
        const measureSystem = (userData?.units || 'imperial')?.toUpperCase();

        const weightMeta = {
            unit: measureSystem,
            value: userData?.weight,
        };
        const heightMeta = {
            unit: measureSystem,
            value: userData?.height,
        };

        yield put({
            type: UPDATE_INFO_SUCCESS,
        });
        yield put(setUserName({ firstName: userData?.name, lastName: userData?.last_name }));
        yield put(setAge(age || 0));
        yield put(setGender(<IGender>userData?.gender || 'female'));
        yield put(setUserEmail(userData?.email));
        yield put(setCountry(userData?.country));
        yield put(setMeasure(<MeasuringSystemType>measureSystem || 'METRIC'));

        if (userData?.weight) yield put(setCurrentWeight(weightMeta || { value: 0 }));
        if (userData?.height) yield put(setTall(heightMeta || { value: 0 }));

        // TODO: check setting measureSystem
        // now this value must setting in the end
        yield put(setMeasure(<MeasuringSystemType>measureSystem));

        yield put(moveUserToPage(true));

        notify && notify(GENERAL_SUCCESS_TOAST_MESSAGE);

        return { status: 'success', userData, token };
    } catch ({ error }) {
        console.error('error: ', error);

        const errorText = error.error === EMAIL_EXISTS_ERROR ? 'common.validationHints.email.exists' : error;

        yield put({
            type: UPDATE_INFO_FAIL,
            payload: errorText,
        });

        setEmailError(errorText);

        notify && notify(GENERAL_ERROR_TOAST_MESSAGE);

        return { status: 'error', ...error };
    }
}

export const updateUserInfoSaga = [takeLatest(UPDATE_INFO, updateUserInfo)];
