export const getFaviconElement = (id = 'favicon'): HTMLLinkElement => {
    return <HTMLLinkElement>document.getElementById(id);
};

export const isEmptyValue = (value: any) => {
    return (
        value === null ||
        value === undefined ||
        (typeof value === 'object' && Object.keys(value).length === 0) ||
        (typeof value === 'string' && value.trim().length === 0)
    );
};

export const isString = (value: any) => {
    if (value === undefined || value === null) return false;

    return typeof value.valueOf() === 'string';
};

export const isObject = (value: any) => {
    const type = typeof value;

    return value !== null && (type === 'object' || type === 'function');
};
