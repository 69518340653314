export const ResultPages = [
    {
        path: 'ResultPages/ResultA',
        route: 'result-a',
    },
    {
        path: 'ResultPages/ResultAConf',
        route: 'result-a-conf',
    },
    {
        path: 'ResultPages/FeedbackSummaryFitlevel',
        route: 'feedback-summary-fitlevel',
    },
];
