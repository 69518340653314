import Solid from 'solid-payment';
import { select, take, call, put } from 'redux-saga/effects';

import config from 'config';

import { selectToken } from 'redux/User/selectors';
import { RootState } from 'redux/rootReducer';
import { selectSolidInited } from 'redux/Payment/selectors';
import * as actionTypes from 'redux/Payment/actionTypes';

import { getLanguage } from 'helpers/utils';

/*
 * Selector. The query depends by the state shape
 */
const getToken = (state: RootState) => selectToken(state);
const getSolidInit = (state: RootState) => selectSolidInited(state);

export function* init() {
    try {
        Solid.setHeaders({
            apiUrl: config.API_URL,
            apiKey: config.API_KEY,
            apiVersion: 2,
            language: getLanguage(),
            token: yield select(getToken),
        });
        console.info('%cinit - SOLID PAYMENT', 'color:green');

        yield put({ type: actionTypes.INIT_SOLID_PAYMENT, payload: true });
    } catch (e) {
        console.error('Error', e);
    }
}

function* tokenWatcher() {
    let previous: string = yield select(getToken);

    while (true) {
        const solidStatus: boolean = yield select(getSolidInit);

        if (solidStatus) return;

        yield take('*'); // listening all actions

        const next: string = yield select(getToken);

        if ((!solidStatus && next) || next !== previous) {
            previous = next;
            yield call(init);
        }
    }
}

export const initSolidPayment = [tokenWatcher()];

export const solidPaymentTestData = {
    tokenWatcher,
    init,
    getSolidInit,
    getToken,
};
