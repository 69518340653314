import {
    NOOM_FLOW_COUNT_PRODUCTS,
    SHORT_NOOM_FLOW_COUNT_PRODUCTS,
    TWO_PRODUCT_NOOM_FLOW_COUNT_PRODUCTS,
    UNIMEAL_FLOW_COUNT_PRODUCTS,
} from '../constants/payments';

import {
    getArrayFromObjectByFieldName,
    checkDifferentValuesByField,
    checkLtv,
    checkMatchPeriodWithPrice,
    checkMatchValueByField,
    checkPreselectedField,
    checkProductPeriods,
    checkProductsCount,
    checkProductsDividedByTwo,
    checkValuePairsIdentityByFieldName,
    checkSamePeriods,
    checkProductsCode,
    checkDifferentTypeOfProduct,
    checkProductsConfig,
} from 'helpers/paymentFlow/utils';
import { checkOptionsCount, checkKeysCount } from 'helpers/onboardingFlow/utils';

import { checkConfig, checkPagesId, checkAnalyticsData } from './configValidator';


export const getNoomFlowValidator = (products) => {
    const arrOfPeriods = getArrayFromObjectByFieldName(products, 'period');
    const arrOfPrices = getArrayFromObjectByFieldName(products, 'price');

    const uniquePeriods = [...new Set(arrOfPeriods)];
    const uniquePrices = [...new Set(arrOfPrices)];

    return [
        {
            exec: checkProductsCount,
            args: { products, count: NOOM_FLOW_COUNT_PRODUCTS },
            message: 'PRODUCTS COUNT',
        },
        { exec: checkProductPeriods, args: { periodsArr: uniquePeriods }, message: 'PERIOD' },
        { exec: checkLtv, args: { products }, message: 'LTV' },
        {
            exec: checkMatchPeriodWithPrice,
            args: { arrWithPrices: uniquePrices, products },
            message: 'MATCH PERIOD WITH PRICES',
        },
    ];
};

export const getTwoProductNoomFlowValidator = (products) => {
    const arrOfPeriods = getArrayFromObjectByFieldName(products, 'period');
    const arrOfPrices = getArrayFromObjectByFieldName(products, 'price');

    const uniquePeriods = [...new Set(arrOfPeriods)];
    const uniquePrices = [...new Set(arrOfPrices)];

    return [
        {
            exec: checkProductsCount,
            args: { products, count: TWO_PRODUCT_NOOM_FLOW_COUNT_PRODUCTS },
            message: 'PRODUCTS COUNT',
        },
        { exec: checkProductPeriods, args: { periodsArr: uniquePeriods }, message: 'PERIOD' },
        { exec: checkLtv, args: { products }, message: 'LTV' },
        {
            exec: checkMatchPeriodWithPrice,
            args: { arrWithPrices: uniquePrices, products },
            message: 'MATCH PERIOD WITH PRICES',
        },
    ];
};

export const getUnimealFlowValidator = (products) => {
    const arrOfPeriods = getArrayFromObjectByFieldName(products, 'period');

    const uniquePeriods = [...new Set(arrOfPeriods)];

    return [
        {
            exec: checkProductsCount,
            args: { products, count: UNIMEAL_FLOW_COUNT_PRODUCTS },
            message: 'PRODUCTS COUNT',
        },
        {
            exec: checkDifferentValuesByField,
            args: { products, fieldName: 'price', count: UNIMEAL_FLOW_COUNT_PRODUCTS },
            message: 'PRICE VALUES',
        },
        {
            exec: checkMatchValueByField,
            args: { products, fieldName: 'period' },
            message: 'PERIOD FIELDS IS NOT SAME',
        },
        { exec: checkProductPeriods, args: { periodsArr: uniquePeriods }, message: 'PERIOD' },
        { exec: checkLtv, args: { products }, message: 'LTV' },
    ];
};

export const getIntroductoryFlowValidator = (products) => {
    const arrOfPeriods = getArrayFromObjectByFieldName(products, 'period');

    const uniquePeriods = [...new Set(arrOfPeriods)];

    return [
        { exec: checkProductsDividedByTwo, args: { products }, message: 'PRODUCTS DIVIDED BY TWO' },
        {
            exec: checkValuePairsIdentityByFieldName,
            args: { products, fieldName: 'trial' },
            message: 'TRIAL',
        },
        { exec: checkPreselectedField, args: { products, withImportantOrder: true }, message: 'PRESELECTED FIELD' },
        { exec: checkProductPeriods, args: { periodsArr: uniquePeriods }, message: 'PERIOD' },
        { exec: checkLtv, args: { products }, message: 'LTV' },
    ];
};

export const getConfigValidator = (config) => {
    return [
        { exec: checkPagesId, args: config, message: 'INVALID PAGE ID' },
        { exec: checkConfig, args: config, message: 'INVALID CONFIG' },
        { exec: checkAnalyticsData, args: config, message: 'INVALID ANALYTICS DATA IN CONFIG' },
    ];
};

export const getShortNoomFlowValidator = (products) => {
    const arrOfPeriods = getArrayFromObjectByFieldName(products, 'period');

    return [
        {
            exec: checkProductsCount,
            args: { products, count: SHORT_NOOM_FLOW_COUNT_PRODUCTS },
            message: 'PRODUCTS COUNT',
        },
        { exec: checkProductPeriods, args: { periodsArr: arrOfPeriods }, message: 'PERIOD' },
        { exec: checkSamePeriods, args: { periodsArr: arrOfPeriods }, message: 'SAME PERIODS' },
        { exec: checkLtv, args: { products }, message: 'LTV' },
    ];
};

export const getUpsellValidator = (products) => {
    return [
        {
            exec: checkProductsCode,
            args: { products },
            message: 'PRODUCTS CODE',
        },
    ];
};

export const getUpsellSubscriptionValidator = (products, config) => {
    return [
        {
            exec: checkProductsCode,
            args: { products },
            message: 'PRODUCTS CODE',
        },
        {
            exec: checkProductsConfig,
            args: config,
            message: 'ERRORS IN CONFIG',
        },
    ];
};

export const getPaymentWithDifferentProductTypeFlowValidator = (products) => {
    const arrOfPeriods = getArrayFromObjectByFieldName(products, 'period');

    const uniquePeriods = [...new Set(arrOfPeriods)];

    return [
        { exec: checkProductsDividedByTwo, args: { products }, message: 'PRODUCTS DIVIDED BY TWO' },
        {
            exec: checkValuePairsIdentityByFieldName,
            args: { products, fieldName: 'period' },
            message: 'EACH ITEM MUST HAVE PERIOD PAIR IN OTHER PRODUCT GROUP',
        },
        { exec: checkDifferentTypeOfProduct, args: { products }, message: 'DIFFERENT TYPE OF PRODUCTS' },
        { exec: checkPreselectedField, args: { products, withImportantOrder: true }, message: 'PRESELECTED FIELD' },
        { exec: checkProductPeriods, args: { periodsArr: uniquePeriods }, message: 'EACH ITEM MUST HAVE PERIOD' },
        { exec: checkLtv, args: { products }, message: 'LTV' },
    ];
};

export const getObConfigValidator = (flow) => {
    const config = flow?.config;

    return [
        {
            exec: checkOptionsCount,
            args: { config },
            message: 'OPTIONS COUNT',
        },
    ];
};

export const getObUnfortunatelyValidator = (flow) => {
    const config = flow?.config;

    return [
        {
            exec: checkKeysCount,
            args: { config },
            message: 'KEYS COUNT',
        },
    ];
};
