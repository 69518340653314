import { all } from 'redux-saga/effects';

import { updateUserInfoSaga } from './user/updateUserInfo';
import { updateSendpulseMailingSaga } from './user/updateSendpulse';
import { skipOnBoardingSaga } from './user/skipOnboarding';
import { signUpUser } from './user/signup';
import { setDefaultCurrency } from './user/currency';
import { setUserProfile } from './user/autologin';
import { frontChat } from './ui/frontChat';
import { validatePayment } from './payment/validatePayment';
import { sendPaymentFailInfo } from './payment/sendPaymentFailInfo';
import { replaceSubscription } from './payment/replaceSubscription';
import { initOneClickPayment } from './payment/oneClickPayment';
import { initSolidPayment } from './payment/initSolid';
import { initPayPal } from './payment/initPayPal';
import { initPaymentGenerator } from './payment/initPaymentGenerator';
import { extraWorkoutTypeSaga } from './onboarding/extraWorkoutType';
import { planDynamicConfigs } from './onboarding/dynamicConfig';

export default function* rootSaga() {
    yield all([
        ...initSolidPayment,
        ...initPayPal,
        ...initPaymentGenerator,
        ...validatePayment,
        ...setDefaultCurrency,
        ...setUserProfile,
        ...initOneClickPayment,
        ...replaceSubscription,
        ...sendPaymentFailInfo,
        ...signUpUser,
        ...frontChat,
        ...updateSendpulseMailingSaga,
        ...updateUserInfoSaga,
        ...skipOnBoardingSaga,
        ...planDynamicConfigs,
        ...extraWorkoutTypeSaga,
    ]);
}
