import { PAYMENT_DEFAULT_CURRENCY } from 'constants/payments';

import { PaymentStore } from 'types/store/paymentStore';
import { ActionType } from 'types/commonInterfaces';

import * as actionTypes from './actionTypes';

const initialState: PaymentStore = {
    isLoading: false,
    paymentValidate: {},
    paymentMethod: 'PAYPAL',
    paymentConfig: {
        paypal: true,
        applaPay: true,
    },
    solidInited: false,
    payPalLink: null,
    applePay: '',
    initPaymentData: null,
    checkoutOrderId: '',
    currentProduct: null,
    mainProduct: null,
    currency: PAYMENT_DEFAULT_CURRENCY,
    exclusiveOfferStatus: false,
    paymentType: null,
    subscriptionId: null,
    upsellId: null,
    purchaseWithoutValue: false,
    isShortFlow: false,
    currentProductCode: null,
    validatePaymentData: null,
    oneClickPaymentPrice: null,
    paymentDiscount: 0,
    // TODO: need check or no
    paymentScreen: null,
    isPaymentScreen: false,
    isCustomPaymentButton: false,
    isHideCustomPaymentButton: false,
    isShowApplePayMethod: false,
    isShowGooglePayMethod: false,
    merchantData: null,
    allProductsFromFirstPayment: [],
    isApplePayAvailable: false,
    isGooglePayAvailable: false,
    currentPlanTab: null,
    skipUpsell: false,
    skipUpsellCoaching: false,
};

const PaymentReducer = (state = initialState, action: ActionType<keyof PaymentStore>) => {
    switch (action.type) {
        case actionTypes.SET_CURRENT_PRODUCT: {
            return {
                ...state,
                currentProduct: action.payload,
            };
        }
        case actionTypes.SET_MAIN_PRODUCT: {
            return {
                ...state,
                mainProduct: action.payload,
            };
        }
        case actionTypes.SET_ORDER_ID:
            return { ...state, checkoutOrderId: action.payload };
        case actionTypes.SET_SUBSCRIPTION_ID:
            return { ...state, subscriptionId: action.payload };
        case actionTypes.INIT_SOLID_PAYMENT:
            return { ...state, solidInited: action.payload };
        case actionTypes.SET_LOADING_STATUS:
            return {
                ...state,
                isLoading: action.payload,
            };
        case actionTypes.SET_PAYMENT_DATA:
            return {
                ...state,
                initPaymentData: action.payload,
            };
        case actionTypes.VALIDATE_PAYMENT_DATA:
            return {
                ...state,
                validatePaymentData: action.payload,
            };
        case actionTypes.SET_PAYPAL_LINK:
            return {
                ...state,
                payPalLink: action.payload,
            };
        case actionTypes.SET_MERCHANT_DATA:
            return {
                ...state,
                merchantData: action.payload,
            };
        case actionTypes.VALIDATE_PAYMENT:
            return {
                ...state,
                isLoading: true,
            };
        case actionTypes.CHANGE_PAYMENT_TYPE:
            return {
                ...state,
                paymentMethod: action.payload,
            };

        case actionTypes.VALIDATE_PAYMENT_SUCCESS:
            return {
                ...state,
                isLoading: false,
            };
        case actionTypes.SET_SHOW_APPLE_PAY_METHOD:
            return {
                ...state,
                isShowApplePayMethod: action.payload,
            };
        case actionTypes.SET_SHOW_GOOGLE_PAY_METHOD:
            return {
                ...state,
                isShowGooglePayMethod: action.payload,
            };
        case actionTypes.SET_UPSELL_PRODUCT_PRICE:
            return {
                ...state,
                oneClickPaymentPrice: action.payload,
            };
        case actionTypes.SET_PAYMENT_CONFIG: {
            const newPaymentConfig = Object.assign({}, state.paymentConfig, action.payload);

            return {
                ...state,
                paymentConfig: newPaymentConfig,
            };
        }
        case actionTypes.VALIDATE_PAYMENT_FAIL: {
            return {
                ...state,
                isLoading: false,
                paymentValidate: {
                    isValid: false,
                    message: action.payload,
                },
            };
        }
        case actionTypes.SET_PAYMENT_CURRENCY:
            return { ...state, currency: action.payload };
        case actionTypes.SET_EXCLUSIVE_OFFER:
            return {
                ...state,
                exclusiveOfferStatus: action.payload,
            };
        case actionTypes.SET_PURCHASE_WITHOUT_VALUE:
            return {
                ...state,
                purchaseWithoutValue: action.payload,
            };
        case actionTypes.SET_PAYMENT_TYPE:
            localStorage.setItem('paymentType', action.payload);

            return { ...state, paymentType: action.payload };
        case actionTypes.SET_UPSELL_PRODUCT_ID:
            return {
                ...state,
                upsellId: action.payload,
            };
        case actionTypes.SET_SHORT_FLOW:
            return { ...state, isShortFlow: action.payload };
        case actionTypes.SET_CURRENT_PRODUCT_CODE:
            return { ...state, currentProductCode: action.payload };
        case actionTypes.SET_PAYMENT_DISCOUNT:
            return { ...state, paymentDiscount: action.payload };
        case actionTypes.SET_IS_PAYMENT_SCREEN:
            return { ...state, isPaymentScreen: action.payload };
        case actionTypes.SET_CUSTOM_PAYMENT_BUTTON:
            return { ...state, isCustomPaymentButton: action.payload };
        case actionTypes.SET_HIDE_CUSTOM_PAYMENT_BUTTON:
            return { ...state, isHideCustomPaymentButton: action.payload };
        case actionTypes.SET_IS_APPLE_PAY_AVAILABLE:
            return { ...state, isApplePayAvailable: action.payload };
        case actionTypes.SET_IS_GOOGLE_PAY_AVAILABLE:
            return { ...state, isGooglePayAvailable: action.payload };
        case actionTypes.SET_ALL_PRODUCTS_FROM_FIRST_PAYMENT:
            return { ...state, allProductsFromFirstPayment: action.payload };
        case actionTypes.SET_CURRENT_PLAN_TAB:
            return { ...state, currentPlanTab: action.payload };
        case actionTypes.SET_SKIP_UPSELL:
            return { ...state, skipUpsell: action.payload };
        case actionTypes.SET_SKIP_UPSELL_COACHING:
            return { ...state, skipUpsellCoaching: action.payload };
        default:
            return state;
    }
};

export default PaymentReducer;
