export const CABINET_URL_LIST = [
    { oldUrl: 'fitcoach-app', newUrl: 'fitcoach-app' },
    { oldUrl: 'account', newUrl: 'account' },
    { oldUrl: 'change-email', newUrl: 'change-email' },
    { oldUrl: 'change-password', newUrl: 'change-password' },
    { oldUrl: 'cancel-confirmation-survey', newUrl: 'account/subscription' },
    { oldUrl: 'cancel-load', newUrl: 'account/subscription' },
    { oldUrl: 'fitness-guides', newUrl: 'fitness-guides' },
    { oldUrl: 'courses', newUrl: 'courses' },
    { oldUrl: 'faq-cabinet', newUrl: 'faq-cabinet' },
    { oldUrl: 'cancel-support', newUrl: 'account/subscription' },
];
