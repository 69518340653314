import { ConfigType } from 'types/store/testaniaStore';

import { Dispatch } from 'types/commonInterfaces';

import * as actionTypes from './actionTypes';

export function setOnboardingConfig(onboardingConfig: ConfigType) {
    return {
        type: actionTypes.SET_ONBOARDING_CONFIG,
        payload: onboardingConfig,
    };
}

export function setTestaniaName(testName: string) {
    return async (dispatch: Dispatch) => {
        try {
            return dispatch({
                type: actionTypes.SET_TESTANIA_NAME,
                payload: testName,
            });
        } catch (error) {
            console.error('error', error);
        }
    };
}

export function setTestaniaLoginStatus(status: boolean) {
    return async (dispatch: Dispatch) => {
        dispatch({
            type: actionTypes.SET_TESTANIA_LOGIN_STATUS,
            payload: status,
        });
    };
}

export function setCompleteOBs(obs: string) {
    return async (dispatch: Dispatch) => {
        dispatch({
            type: actionTypes.SET_COMPLETE_OBS,
            payload: obs,
        });
    };
}

export function clearCompleteOBs() {
    return async (dispatch: Dispatch) => {
        dispatch({
            type: actionTypes.CLEAR_COMPLETE_OBS,
        });
    };
}

export function setCurrenyBranchName(branchName: string) {
    return async (dispatch: Dispatch) => {
        dispatch({
            type: actionTypes.SET_CURRENT_BRANCH_NAME,
            payload: branchName,
        });
    };
}

export function setFlowLink(flowLink: string) {
    return {
        type: actionTypes.SET_FLOW_LINK,
        payload: flowLink,
    };
}
