import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import ReactDOM from 'react-dom';
import React from 'react';

import 'services/Translation/i18n';
import Yahoo from 'services/Yahoo';
import Sentry from 'services/Sentry/SentryInstance';

import configureStore from './configureStore';

import ErrorBoundary from 'modules/ErrorHandler';
import App from './App';

import './styles.css';

const { store, persistor } = configureStore();

Sentry.init();
Yahoo.init();

ReactDOM.render(
    <Provider store={store}>
        <PersistGate persistor={persistor}>
            <ErrorBoundary>
                <App />
            </ErrorBoundary>
        </PersistGate>
    </Provider>,
    document.getElementById('app')
);
