import { DEFAULT_NAVIGATOR_LANGUAGE, AVAILABLE_LOCALIZATIONS } from 'constants/localization';

export const getNavigatorLanguage = () => {
    let language;

    if (navigator?.languages?.length) {
        language = navigator.languages[0];
    } else {
        language = navigator?.language || DEFAULT_NAVIGATOR_LANGUAGE;
    }

    return language?.split('-')[0];
};

export const getLocalizationFromAvailable = () => {
    const navigatorLanguage = getNavigatorLanguage();

    return verifyLanguage(navigatorLanguage);
};

export const getCurrentLocalization = () => {
    const routerLang = window.location.pathname.split('/')[1];

    return verifyLanguage(routerLang);
};

export const verifyLanguage = (lang: string) => AVAILABLE_LOCALIZATIONS.includes(lang) ? lang : null;
