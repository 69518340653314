import { IText, Text as LibText } from 'wikr-core-components';
import { Trans } from 'react-i18next';
import React, { FC } from 'react';

interface Props extends IText {
    values?: Record<string, string>;
    components?: Record<string, React.ReactNode>;
}

const Text: FC<Props> = (props) => {
    return (
        <LibText {...props} text={undefined}>
            {/* condition is the fallback for old logic. Remove after refactoring */}
            {props.children || <Trans i18nKey={props.text} values={props.values} components={props.components} />}
        </LibText>
    );
};

export { Text };
