import { IUIEffectStore } from 'types/store/uiEffectsStore';
import { ActionType } from 'types/commonInterfaces';

import * as actionTypes from './actionTypes';

const initialState: IUIEffectStore = {
    hide_header: null,
    modalIsOpen: false,
    issueType: 'ERROR',
    issueTitle: '',
    issueDescription: '',
    appLoader: false,
    hideProgressBar: null,
    logoUrl: null,
    hidePayPal: false,
    isSideBarOpen: false,
    initFrontChat: null,
    redirectPath: '',
    inlineTheme: null,
};

const UiEffects = (state = initialState, action: ActionType<keyof IUIEffectStore>) => {
    switch (action.type) {
        case actionTypes.REDIRECT_TO:
            return { ...state, redirectPath: action.payload };

        case actionTypes.HIDE_HEADER:
            return { ...state, hide_header: action.payload };

        case actionTypes.SHOW_MODAL: {
            return { ...state, modalIsOpen: true, ...((action.payload as unknown) as Record<string, unknown>) };
        }

        case actionTypes.HIDE_MODAL: {
            return { ...state, modalIsOpen: false, issueDescription: '', issueTitle: '' };
        }
        case actionTypes.SET_LOADER_STATE: {
            return { ...state, appLoader: action.payload };
        }

        case actionTypes.HIDE_PROGRESS_BAR: {
            return { ...state, hideProgressBar: action.payload };
        }

        case actionTypes.SET_LOGO_URL:
            return {
                ...state,
                logoUrl: action.payload,
            };

        case actionTypes.SET_INLINE_THEME:
            return {
                ...state,
                inlineTheme: action.payload,
            };

        case actionTypes.SHOW_SIDEBAR:
            return { ...state, isSideBarOpen: action.payload };

        case actionTypes.INIT_FRONT_CHAT:
            return { ...state, initFrontChat: action.payload };

        case actionTypes.HIDE_PAYPAL:
            return { ...state, hidePayPal: action.payload };

        default:
            return state;
    }
};

export default UiEffects;
