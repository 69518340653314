import { put, takeLatest, select } from 'redux-saga/effects';

import { selectCountry } from 'redux/User/selectors';
import { SET_COUNTRY } from 'redux/User/actionTypes';
import { SET_PAYMENT_CURRENCY } from 'redux/Payment/actionTypes';

import { PAYMENT_DEFAULT_CURRENCY } from 'constants/payments';
import { COUNTRIES_CURRENCY_LIST } from 'constants/countriesData';

export function* setCurrency() {
    const userCountry: string = yield select(selectCountry);

    const matchedCountry = COUNTRIES_CURRENCY_LIST.find((item) => item.code === userCountry);

    if (userCountry && matchedCountry) {
        yield put({ type: SET_PAYMENT_CURRENCY, payload: matchedCountry.currency });
    } else {
        yield put({ type: SET_PAYMENT_CURRENCY, payload: PAYMENT_DEFAULT_CURRENCY });
    }
}

export const setDefaultCurrency = [takeLatest(SET_COUNTRY, setCurrency)];
