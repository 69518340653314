import { Redirect, RouteProps } from 'react-router-dom';
import React from 'react';

import lazyWithRetry from 'helpers/lazyWithRetry';
import { pathWithRegularExpression, generateRoute } from '../helpers/helpers';

import {
    StartPages,
    OnboardingPages,
    PaymentPages,
    UpsellPages,
    ResultPages,
    OtherPages,
} from '../routesMap/publicRoutesMap';

const PreloadTestania = lazyWithRetry(() => import('pages/OtherPages/PreloaderTestania/PreloaderTestania'));

const PUBLIC_ROUTES = [
    ...StartPages,
    ...OnboardingPages,
    ...PaymentPages,
    ...UpsellPages,
    ...ResultPages,
    ...OtherPages,
];

const routes: Array<RouteProps> = [
    {
        path: pathWithRegularExpression(''),
        render: (props: any) => <PreloadTestania {...props} />,
        exact: true,
    },
];

for (const item of PUBLIC_ROUTES) {
    const { route, path } = item;

    const payload = generateRoute(route, path);

    routes.push(payload);
}

routes.push({
    path: '*',
    render: () => <Redirect to="/en" />,
});

export default routes;
